import React from 'react';
import { useSelector } from 'react-redux';

import { Routes, Route } from 'react-router-dom';
import loadable from '@loadable/component';
import { timeout } from 'promise-timeout';

import Loader from 'components/Loader';

import { selectors as dealerSelectors } from 'state/dealer';
import { RootState } from 'state/types';

import useHasActionPermission from 'core/hooks/useHasActionPermission';
import hasProduct from 'core/hasProduct';
import { PRICE_SIGN } from 'core/constants/products';

import SpringNotifications from './SpringNotifications';

/**
 * Remove promiseMinDelay.js and delay.js after razzle babels node_modules
 * We can then use https://github.com/sindresorhus/p-min-delay again
 * */
import pMinDelay from '../core/promiseMinDelay';

const MIN_DELAY = typeof window !== 'undefined' ? 200 : 0;
const MAX_DELAY = 2000;

const loadBoundaries = mod => timeout(pMinDelay(mod, MIN_DELAY), MAX_DELAY);

const Offer = loadable(() => loadBoundaries(import('../routes/offer')));
const EquipmentLists = loadable(() => loadBoundaries(import('../routes/offer/equipmentlists')));

const NotFound = loadable(() => loadBoundaries(import('routes/notFound')));
const Home = loadable(() => loadBoundaries(import('routes/home')));
const Statistics = loadable(() => loadBoundaries(import('routes/statistics')));
const Vehicles = loadable(() => loadBoundaries(import('routes/vehicles')));
const News = loadable(() => loadBoundaries(import('routes/news')));
const Tagplan = loadable(() => loadBoundaries(import('routes/tagplan')));
const Pricing = loadable(() => loadBoundaries(import('routes/pricing')));
const AdPricing = loadable(() => loadBoundaries(import('routes/adpricing')));
const BumpFilters = loadable(() => loadBoundaries(import('routes/bumpFilters')));
const BumpHistory = loadable(() => loadBoundaries(import('routes/bumpHistory')));
const Dealer = loadable(() => loadBoundaries(import('routes/dealer')));
const InvoiceHistory = loadable(() => loadBoundaries(import('routes/invoiceHistory')));
const DealerDescription = loadable(() => loadBoundaries(import('routes/dealer/description')));
const ChangePassword = loadable(() => loadBoundaries(import('routes/changePassword')));
const DCB = loadable(() => loadBoundaries(import('routes/dcb')));
const DCBHistory = loadable(() => loadBoundaries(import('routes/dcb/history')));
const ChangePrices = loadable(() => loadBoundaries(import('routes/changePrices')));
const ChangeRegNumber = loadable(() => loadBoundaries(import('routes/changeRegNumber')));
const ImportStatus = loadable(() => loadBoundaries(import('routes/importStatus')));
const VehiclesHistory = loadable(() => loadBoundaries(import('routes/vehicles/history')));
const Sellers = loadable(() => loadBoundaries(import('routes/sellers')));
const Finance = loadable(() => loadBoundaries(import('routes/finance')));
const LoginToDealer = loadable(() => loadBoundaries(import('routes/iua/loginToDealer')));

const Signup = loadable(() => loadBoundaries(import('routes/iua/signup')));
const VerifyUser = loadable(() => loadBoundaries(import('routes/iua/verify')));
const CompleteInviteSignup = loadable(() => loadBoundaries(import('routes/iua/invite')));
const Users = loadable(() => loadBoundaries(import('routes/users')));
const AddUser = loadable(() => loadBoundaries(import('routes/users/add')));
const EditUser = loadable(() => loadBoundaries(import('routes/users/edit')));
const PriceSignsMovedInfo = loadable(() => loadBoundaries(import('routes/priceSigns')));
const UserSettingsPage = loadable(() => loadBoundaries(import('routes/iua/userSettings')));
const NewPassword = loadable(() => loadBoundaries(import('routes/iua/newPassword')));
const UpdateEmail = loadable(() => loadBoundaries(import('routes/iua/updateEmail')));

const Authenticated = () => {
    const { loading, error } = useSelector((state: RootState) => dealerSelectors.getDealer(state));

    const { products } = useSelector((state: RootState) => state.dealer.dealerApi);

    const canUpdateDealer = useHasActionPermission('update', 'dealer');
    const canImpersonateDealer = useHasActionPermission('run', 'impersonate');

    if (loading || error) {
        return <Loader autocenter size={3} className="mt-3" />;
    }

    return (
        <>
            <div className="main-container">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/offer" element={<Offer />} />
                    <Route path="/offer/:id" element={<Offer />} />
                    <Route path="/offer/:copiedOfferId/copy" element={<Offer />} />
                    <Route path="/offer/equipment" element={<EquipmentLists />} />
                    <Route path="/vehicles" element={<Vehicles />} />
                    <Route path="/vehicles/history" element={<VehiclesHistory />} />
                    <Route path="/statistics" element={<Statistics />} />
                    <Route path="/dealer/pricing" element={<AdPricing />} />
                    <Route path="/news" element={<News />} />
                    <Route path="/news/:articleId" element={<News />} />
                    <Route path="/tagplan" element={<Tagplan />} />

                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/pricing/:compareId" element={<Pricing />} />
                    <Route path="/pricing/compare/:compareId/to/:toId" element={<Pricing />} />

                    <Route path="/bumpsettings" element={<BumpFilters />} />
                    <Route path="/adrenewals/history" element={<BumpHistory />} />
                    <Route path="/dealer/edit" element={<Dealer />} />
                    <Route path="/dealer/invoicehistory" element={<InvoiceHistory />} />
                    <Route path="/dealer/presentation" element={<DealerDescription />} />
                    <Route path="/dealer/changepassword" element={<ChangePassword />} />
                    <Route path="/dcb/orders" element={<DCB />} />
                    <Route path="/dcb/history" element={<DCBHistory />} />
                    <Route path="/prices" element={<ChangePrices />} />
                    <Route path="/edit-regno" element={<ChangeRegNumber />} />
                    <Route path="/import-status" element={<ImportStatus />} />
                    {hasProduct({ products, productCode: PRICE_SIGN }) && (
                        <Route path="/pricesigns" element={<PriceSignsMovedInfo />} />
                    )}
                    <Route path="/dealer/finance" element={<Finance />} />
                    <Route path="/dealer/sellers" element={<Sellers />} />

                    <Route path="/users/signup" element={<Signup />} />
                    <Route path="/users/verify/:token" element={<VerifyUser />} />
                    <Route path="/users/invite/:loggedInCode" element={<CompleteInviteSignup />} />
                    <Route path="/password/:token" element={<NewPassword />} />
                    <Route path="/user/update-email/:token" element={<UpdateEmail />} />

                    {canUpdateDealer && (
                        <>
                            <Route path="/user/manage-users" element={<Users />} />
                            <Route path="/user/add-users" element={<AddUser />} />
                            <Route path="/user/edit-user/:id" element={<EditUser />} />
                        </>
                    )}

                    {canImpersonateDealer && <Route path="/dealer/impersonate/:dealerId" element={<LoginToDealer />} />}
                    <Route path="/user/settings" element={<UserSettingsPage />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>

            <SpringNotifications />
        </>
    );
};

export default Authenticated;
