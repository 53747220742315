import Cookie from 'js-cookie';

const invalidDomain = '.bytbil.com';
const cookies = [
    'featurePromotion',
    'viewMode',
    'sortSettings',
    'ft',
    'dealer',
    'vehiclesFilterSelection',
    'vehiclesDatesFilter',
    'bumpSettings'
];

export default () =>
    cookies.forEach(cookie => {
        Cookie.remove(cookie, {
            domain: invalidDomain
        });
    });
