import React from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import addDefaultImgSrc from 'core/addDefaultImgSrc';
import { thousandFormatter } from 'core/numberFormat';
import MarketplaceLinks from 'components/Offer/MarketplaceLinks';
import { Order } from './types';

interface Props {
    order: Order;
    handleDismiss(e: React.MouseEvent): void;
    handleDismissAll(e: React.MouseEvent): void;
}

const NotificationCard = ({ order, handleDismiss, handleDismissAll }: Props) => {
    const { title, created, mainMediaUrl, regNumber, modelYear, miles, color, price, blocketUrl, bytbilUrl } =
        order || {};
    return (
        <div className="notification-card mb-2 p-4">
            <div className="d-flex justify-content-between pb-3">
                <a href="/dcb/orders" role="button" target="_blank" onClick={handleDismissAll} className="header">
                    Ny reservation från Blocket!
                </a>
                <div className="d-flex align-items-center justify-content-center">
                    <p className="text-muted m-0">{format(parseISO(created), 'HH:mm')}</p>
                    <i role="button" tabIndex={0} className="fa fa-close ml-3" onClick={handleDismiss} />
                </div>
            </div>

            <div className="d-flex border border-light">
                <a href="/dcb/orders" role="button" target="_blank" onClick={handleDismissAll}>
                    <div role="button" tabIndex={0} className="image-wrapper">
                        <img onError={addDefaultImgSrc} src={mainMediaUrl} alt={title} />
                    </div>
                </a>
                <div className="px-3 py-2">
                    <p className="font-weight-bold mb-1">{title}</p>
                    <div className="details mb-1">
                        <p>{regNumber}</p>
                        <p>{modelYear}</p>
                        <p>{thousandFormatter(miles, ' mil')}</p>
                        <p>{color}</p>
                    </div>
                    <p className="font-weight-bold mb-2">{thousandFormatter(price, ' kr')}</p>
                    <MarketplaceLinks blocketUrl={blocketUrl} bytbilUrl={bytbilUrl} />
                </div>
            </div>
        </div>
    );
};

export default NotificationCard;
