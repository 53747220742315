import * as STATISTICS from '../actionTypes';

const msInDay = 1000 * 3600 * 24;
const getDefaultPeriod = () => {
    const currentStop = new Date(new Date().setDate(new Date().getDate() - 1));
    const currentStart = new Date(currentStop.getTime() - msInDay * 29);
    const compareStop = new Date(new Date(currentStart).setDate(currentStart.getDate() - 1));
    const compareStart = new Date(compareStop.getTime() - msInDay * 29);
    return {
        currentStart,
        currentStop,
        compareStart,
        compareStop
    };
};

export const defaultState = {
    dealerId: undefined,
    period: getDefaultPeriod(),
    showFilters: false
};

export default function ui(state = defaultState, action) {
    switch (action.type) {
        case STATISTICS.SET_DEALER_ID:
            return {
                ...state,
                dealerId: parseInt(action.dealerId, 10)
            };

        case STATISTICS.SET_PERIOD:
            return {
                ...state,
                period: action.period
            };

        default:
            return state;
    }
}
