import React, { createContext } from 'react';
import { GlobalContextProps } from 'state/types';

export const GlobalContext = createContext({} as GlobalContextProps);

interface Props {
    context: GlobalContextProps;
    children: React.ReactNode;
}

const GlobalContextComponent = ({ context, children }: Props) => (
    <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>
);

export default GlobalContextComponent;
