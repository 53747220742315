import React, { useRef, useState, useMemo, useEffect, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { animated, useTransition } from '@react-spring/web';
import NotificationCard from './NotificationCard';
import { Order } from './types';

interface MessageHubProps {
    config?: {
        tension: number;
        friction: number;
        precision: number;
    };
    timeout?: number;
    children: (add: AddFunction) => void;
}

type AddFunction = (order: Order) => void;

interface Item {
    key: number;
    order: Order;
}

interface State {
    dcb: {
        orders: Order[];
    };
}

function MessageHub({
    config = { tension: 125, friction: 20, precision: 0.1 },
    timeout = 10000000,
    children
}: MessageHubProps) {
    const refMap = useMemo(() => new WeakMap(), []);
    const cancelMap = useMemo(() => new WeakMap(), []);
    const [items, setItems] = useState<Item[]>([]);

    const transitions = useTransition(items, {
        from: { opacity: 0, height: 0, life: '100%' },
        keys: item => item.key,
        enter: item => async (next, cancel) => {
            cancelMap.set(item, cancel);
            await next({ opacity: 1, height: refMap.get(item).offsetHeight });
            await next({ life: '0%' });
        },
        leave: [{ opacity: 0 }, { height: 0 }],
        onRest: (result, ctrl, item) => {
            setItems(state => state.filter(i => i.key !== item.key));
        },
        config: (item, index, phase) => key => phase === 'enter' && key === 'life' ? { duration: timeout } : config
    });

    const idRef = useRef(0);
    // ...
    const handleOrderChange = order => {
        setItems(state => {
            idRef.current += 1;
            return [...state, { key: idRef.current, order }];
        });
    };

    const handleDismiss = (e: MouseEvent, item, life) => {
        e.stopPropagation();
        if (cancelMap.has(item) && life.get() !== '0%') cancelMap.get(item)();
    };

    const handleDismissAll = () => {
        setItems([]);
    };

    useEffect(() => {
        children((order: Order) => {
            handleOrderChange(order);
        });
    });

    return (
        <div className="spring-container">
            {transitions(({ life, ...style }, item) => (
                <animated.div className="spring-message" style={style}>
                    <div className="spring-content" ref={(ref: HTMLDivElement) => ref && refMap.set(item, ref)}>
                        <NotificationCard
                            order={item.order}
                            key={item.key}
                            handleDismiss={e => handleDismiss(e, item, life)}
                            handleDismissAll={handleDismissAll}
                        />
                    </div>
                </animated.div>
            ))}
        </div>
    );
}

export default function Spring() {
    const ref = useRef<null | AddFunction>(null);

    const orders = useSelector((state: State) => state?.dcb?.orders);

    useEffect(() => {
        orders.map(order => ref.current?.(order));
    }, [orders]);

    return (
        <MessageHub>
            {(add: AddFunction) => {
                ref.current = add;
            }}
        </MessageHub>
    );
}
