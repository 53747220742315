import React from 'react';

import classNames from 'classnames';
import { useLocation } from 'react-router';
import Link from '../NavigationLink/NavigationLink';

interface Props {
    id: string;
    to: string;
    rel?: string;
    onClick: (e, id) => void;
    legacy?: boolean;
    active?: boolean;
    target?: string;
    className?: string;

    children?: React.ReactNode;
}

const NavigationItem = React.memo(({ id, to, rel, onClick, legacy, className, active, target, children }: Props) => {
    const { pathname } = useLocation();

    return (
        <li className="nav-item">
            <Link
                to={to}
                onClick={e => {
                    onClick(e, id);
                }}
                legacy={legacy}
                className={classNames('nav-link', className, (active || pathname === to) && 'active')}
                target={target}
                rel={rel}
            >
                {children}
            </Link>
        </li>
    );
});

export default NavigationItem;
