import React from 'react';

import { Badge } from '@bytbil/bootstrap-react';

import NavigationItem from 'components/Navigation/NavigationItem';
import ComposeNavigationItems from './ComposeNavigationItems';

const AccountNavigationProductSubItems = React.memo(() => (
    <ComposeNavigationItems origin="header">
        {({ onNavigationClick }) => (
            <React.Fragment>
                <NavigationItem
                    id="publish-ads"
                    to="https://www.blocket.se/for-foretag/fordonshandlare/publicering_av_annons"
                    className="ml-4"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onNavigationClick}
                >
                    Publicera annonser
                </NavigationItem>

                <NavigationItem
                    id="sale-optimization"
                    to="https://www.blocket.se/for-foretag/fordonshandlare/fornyelse"
                    className="ml-4"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onNavigationClick}
                >
                    {' '}
                    Försäljningsoptimering
                </NavigationItem>

                <NavigationItem
                    id="price-check"
                    to="https://www.blocket.se/for-foretag/fordonshandlare/priskollen"
                    className="ml-4 d-flex justify-content-between"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onNavigationClick}
                >
                    {' '}
                    Priskollen
                    <Badge tag="span" pill={false} className="pro-badge p-1">
                        PRO
                    </Badge>
                </NavigationItem>
                <NavigationItem
                    id="breakfast-check"
                    to="https://www.blocket.se/for-foretag/fordonshandlare/frukostkollen"
                    className="ml-4 d-flex justify-content-between"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onNavigationClick}
                >
                    {' '}
                    Frukostkollen
                    <Badge tag="span" pill={false} className="pro-badge p-1">
                        PRO
                    </Badge>
                </NavigationItem>
                <NavigationItem
                    id="call-check"
                    to="https://www.blocket.se/for-foretag/fordonshandlare/samtalskollen"
                    className="ml-4 d-flex justify-content-between"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onNavigationClick}
                >
                    {' '}
                    Samtalskollen
                    <Badge tag="span" pill={false} className="pro-badge p-1">
                        PRO
                    </Badge>
                </NavigationItem>
            </React.Fragment>
        )}
    </ComposeNavigationItems>
));

export default AccountNavigationProductSubItems;
