import * as actionTypes from './actionTypes';

export const addXHRInstance = xhr => ({
    type: actionTypes.ADD_XHR_INSTANCE,
    instance: xhr
});

export const clearXHRInstances = {
    type: actionTypes.CLEAR_XHR_INSTANCES
};
