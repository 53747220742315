import React from 'react';
import { Navbar } from '@bytbil/bootstrap-react';

import Logotype from 'components/Logotype';

interface Props {
    children: React.ReactNode;
}

const Header = () => (
    <Navbar expand="lg" tag="header" className="bg-dark-alt px-0 header-main d-none d-xl-flex" dark>
        <div className="container d-flex justify-content-between w-100">
            <div className="d-flex align-items-center flex-row flex-grow">
                <Logotype className="header-logo-above" onClick={() => {}} />
            </div>
        </div>
    </Navbar>
);

const LoggedOutLayout = (props: Props) => {
    const { children } = props;

    return (
        <div className="main">
            <Header />

            <main className="main-content">{children}</main>
        </div>
    );
};

export default LoggedOutLayout;
