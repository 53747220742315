export default (fetch, { baseUrl, headers }) => {
    const defaultHeaders = {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'application/json'
    };

    const multipartHeaders = {
        'X-Requested-With': 'XMLHttpRequest'
    };

    const defaults = {
        method: 'POST',
        mode: 'same-origin',
        credentials: 'same-origin'
    };

    return (url, options, multipart) =>
        fetch(`${baseUrl}${url}`, {
            ...defaults,
            ...options,
            headers: {
                ...(multipart ? multipartHeaders : defaultHeaders),
                ...(options && options.headers),
                ...headers
            }
        });
};
