import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as generalActions } from 'state/general';
import { actions as uiActions, constants as uiConstants, selectors as uiSelectors } from 'state/ui';

interface Props {
    origin: string;

    children: (props: {
        onNavigationClick: (e: React.MouseEvent<HTMLAnchorElement>, target: string) => void;
        onCustomerServiceClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    }) => React.ReactNode;
}
const ComposeNavigationItems: React.FC<Props> = ({ origin, children }: Props) => {
    const dispatch = useDispatch();

    const offcanvas = useSelector(uiSelectors.getShowOffcanvas);

    const showCustomerServiceModal = e => {
        e.preventDefault();

        dispatch(uiActions.modal('customerService', true, uiConstants.MODAL_ORIGIN_FOOTER));
    };

    const handleNavigationClick = (e: React.MouseEvent<HTMLAnchorElement>, target: string) => {
        if (offcanvas) {
            dispatch(uiActions.showOffcanvas(false));
        }
        dispatch(generalActions.navigationClick(target, origin));
    };

    return (
        <>
            {children({
                onNavigationClick: handleNavigationClick,
                onCustomerServiceClick: showCustomerServiceModal
            })}
        </>
    );
};

export default ComposeNavigationItems;
