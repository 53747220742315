export const SET_STORAGE_COST = '@VEHICLES/SET_STORAGE_COST';
export const SET_COLUMN_PREFERENCES = '@VEHICLES/SET_COLUMN_PREFERENCES';
export const SET_UNCHECKED_GEARBOX_COLUMN = '@VEHICLES/SET_UNCHECKED_GEARBOX_COLUMN';
export const SET_RANK_SETTINGS = '@VEHICLES/SET_RANK_SETTINGS';
export const SET_SORT_STATE = '@VEHICLES/SET_SORT_STATE';
export const SET_FILTER_SELECTION = '@VEHICLES/SET_FILTER_SELECTION';
export const SET_DATE_FILTER = '@VEHICLES/SET_DATE_FILTER';
export const SET_SELECTED_VEHICLES = '@VEHICLES/SET_SELECTED_VEHICLES';
export const SET_VEHICLES = '@VEHICLES/SET_VEHICLES';
export const SET_SHOW_MAIN_THUMB = '@VEHICLES/SET_SHOW_MAIN_THUMB';
export const RESET_FILTERS = '@VEHICLES/RESET_FILTERS';

// GTM TRACKING
export const SET_CHOSEN_COLUMNS = '@VEHICLES/SET_CHOSEN_COLUMNS';
export const SET_MOVE_VEHICLE_MODAL_OPEN = '@VEHICLES/SET_MOVE_VEHICLE_MODAL_OPEN';
export const SET_BUMP_MODAL_OPEN = '@VEHICLES/SET_BUMP_MODAL_OPEN';
export const SET_STATISTICS_PERIOD_FILTER = '@VEHICLES/SET_STATISTICS_PERIOD_FILTER';
export const SET_VIEW_MODE = '@VEHICLES/SET_VIEW_MODE';
export const SET_VIEW_MODE_PREFERENCE = '@VEHICLES/SET_VIEW_MODE_PREFERENCE';
export const SET_DELETE_MODAL_OPEN = '@VEHICLES/SET_DELETE_MODAL_OPEN';
export const SET_DELETE_OFFER = '@VEHICLES/SET_DELETE_OFFER';
export const SET_DOWNLOAD_CSV = '@VEHICLES/SET_DOWNLOAD_CSV';
export const SET_STATISTICS_MODAL_OPEN = '@VEHICLES/SET_STATISTICS_MODAL_OPEN';
export const SET_BUMP_HISTORY_MODAL_OPEN = '@VEHICLES/SET_BUMP_HISTORY_MODAL_OPEN';
export const SET_FREETEXT_SEARCH = '@VEHICLES/SET_FREETEXT_SEARCH';
export const SET_FREETEXT_RESET = '@VEHICLES/SET_FREETEXT_RESET';
export const SET_STATUS_OPTION = '@VEHICLES/SET_STATUS_OPTION';
export const SET_DEALER_OPTION = '@VEHICLES/SET_DEALER_OPTION';
export const SET_VEHICLE_TYPE = '@VEHICLES/SET_VEHICLE_TYPE';
export const SET_SHOW_FILTER = '@VEHICLES/SET_SHOW_FILTER';
export const SET_CONDITION_FILTER = '@VEHICLES/SET_CONDITION_FILTER';
export const SET_PRIVATE_LEASING_FILTER = '@VEHICLES/SET_PRIVATE_LEASING_FILTER';
export const SET_OFFERS_WITHOUT_IMAGES_FILTER = '@VEHICLES/SET_OFFERS_WITHOUT_IMAGES_FILTER';
