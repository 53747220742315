export const SET_AUTHENTICATED = '@USER/SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = '@USER/SET_UNAUTHENTICATED';
export const SET_USER_OBJECT = '@USER/SET_USER_OBJECT';
export const SET_USER_FROM_COOKIE = '@USER/SET_USER_FROM_COOKIE';
export const SET_DEALER = '@USER/SET_DEALER';
export const SET_DEALER_ERROR = '@USER/SET_DEALER_ERROR';

export const USER_LOGIN_REQUEST = '@USER/USER_LOGIN_REQUEST';
export const USER_LOGIN_RESPONSE = '@USER/USER_LOGIN_RESPONSE';
export const USER_LOGIN_ERROR = '@USER/USER_LOGIN_ERROR';

export const USER_LOGOUT_REQUEST = '@USER/USER_LOGOUT_REQUEST';
export const USER_LOGOUT_RESPONSE = '@USER/USER_LOGOUT_RESPONSE';
export const USER_LOGOUT_ERROR = '@USER/USER_LOGOUT_ERROR';
