import * as DEALER from './actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const setDealerApiLoading = ({ loading }) => ({
    type: DEALER.SET_DEALER_API_LOADING,
    loading
});
export const setDealerApiError = ({ error }) => ({
    type: DEALER.SET_DEALER_API_ERROR,
    error
});
export const setDealerApiData = ({ data }) => ({
    type: DEALER.SET_DEALER_API_DATA,
    data
});
