import * as actionTypes from './actionTypes';

const defaultState = {
    pool: []
};

export default function connections(state = defaultState, action) {
    switch (action.type) {
        case actionTypes.ADD_XHR_INSTANCE:
            return {
                ...state,
                pool: [...state.pool, action.instance]
            };
        case actionTypes.CLEAR_XHR_INSTANCES:
            return defaultState;
        default:
            return state;
    }
}
