interface Tag {
    tag: string;
}
interface Props {
    tags: Tag[];
    tagName: string;
}

const hasTag = ({ tags, tagName }: Props) => !!tags.find(({ tag }) => tag === tagName);

export default hasTag;
