// https://pulse.schibsted.io/tracking/start/schema-browser
interface Props {
    action: 'Click' | 'Zoom' | 'Scroll' | 'Drag';
    target?: {
        type: 'Application';
    };
    object: {
        type?: 'UIElement';
        /** object.name should be something unique that identifies this event */
        name: string;
        custom?: object;
    };
}

const pulseTracker = ({
    action,
    target: { type: targetType = 'Application' } = { type: 'Application' },
    object
}: Props) => {
    pulse(tracker => {
        tracker.track('engagementEvent', {
            action,
            target: {
                '@id': 'sdrn:schibsted:application:bytbilse',
                '@type': targetType
            },
            object: {
                '@type': object.type,
                name: object.name,
                'spt:custom': object.custom
            }
        });
    });
};

export default pulseTracker;
