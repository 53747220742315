import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

/**
 * This is a temporary banner to create an IUA account
 * Not bothering with classes etc
 */

const MOBILE_HEADER_BREAKPOINT = 768;
const DESKTOP_HEADER_BREAKPOINT = 1200;

const layoutStyles = {
    mobile: {
        marginBottom: '-56px',
        paddingTop: '62px',
        paddingLeft: '10px',
        paddingBottom: '10px'
    },
    tablet: {
        marginBottom: '-80px',
        paddingTop: '90px',
        paddingLeft: '25px',
        paddingBottom: '10px'
    },
    default: {
        marginBottom: '0',
        paddingTop: '10px',
        paddingLeft: '40px',
        paddingBottom: '10px'
    }
};

const CreateIUAAccountBanner = () => {
    // We actually have 3 breakpoints so we need to update the logic here
    const [currentLayout, setCurrentLayout] = useState(() => {
        const width = window.innerWidth;

        if (width < MOBILE_HEADER_BREAKPOINT) return 'mobile';
        if (width > MOBILE_HEADER_BREAKPOINT && width <= DESKTOP_HEADER_BREAKPOINT) return 'tablet';

        return 'default';
    });

    const [hover, setHover] = useState(false);

    // We don't want to show the banner on the actual page that the banner links to...
    const showBanner = typeof window !== 'undefined' ? window.location.pathname !== '/users/signup' : true;

    // styles doesn't like single quotes so breaking these out...
    const display = showBanner ? 'block' : 'none';
    const showUnderline = hover ? 'underline' : 'none';

    const currentStyles = layoutStyles[currentLayout] || layoutStyles.default;

    const { marginBottom, paddingTop, paddingLeft, paddingBottom } = currentStyles;

    const styles = {
        backgroundColor: 'rgb(36,197,161)',
        color: '#474747',
        textDecoration: showUnderline,
        marginBottom,
        paddingTop,
        paddingBottom,
        paddingLeft,
        zIndex: 1,
        display
    };

    const toggleHover = () => {
        setHover(!hover);
    };

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width < MOBILE_HEADER_BREAKPOINT) setCurrentLayout('mobile');
            else if (width > MOBILE_HEADER_BREAKPOINT && width <= DESKTOP_HEADER_BREAKPOINT) setCurrentLayout('tablet');
            else setCurrentLayout('desktop');
        };

        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return (
        <NavLink to="/users/signup" className="text-underline">
            <div style={styles} className="position-relative" onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
                Vi lanserar individuella användarkonton för Blocket admin! Klicka här för att skapa ett konto och logga
                in
            </div>
        </NavLink>
    );
};

export default CreateIUAAccountBanner;
