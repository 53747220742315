import gql from 'graphql-tag';

export default gql`
    query GetDealerNotifications($dealerId: Int!) {
        dealerNotifications(dealerId: $dealerId) {
            id
            type
            message
            url
        }
    }
`;
