import * as DEALER from './actionTypes';

export const defaultState = {
    dealerApi: {
        products: [],
        tags: []
    },
    loading: false,
    error: false
};

/**
 * Dealer reducer
 */
const dealer = (state = defaultState, action) => {
    switch (action.type) {
        case DEALER.SET_DEALER_API_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case DEALER.SET_DEALER_API_ERROR:
            return {
                ...state,
                error: action.error
            };
        case DEALER.SET_DEALER_API_DATA:
            return {
                ...state,
                loading: false,
                dealerApi: action.data
            };

        default:
            return state;
    }
};

export default dealer;
