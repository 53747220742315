import React from 'react';

import NavigationItem from 'components/Navigation/NavigationItem';
import ComposeNavigationItems from './ComposeNavigationItems';

interface Props {
    className?: string;
}

const DCBNavigationItems = React.memo(({ className }: Props) => (
    <ComposeNavigationItems origin="header">
        {({ onNavigationClick }) => (
            <React.Fragment>
                <NavigationItem id="dcb-orders" to="/dcb/orders" onClick={onNavigationClick} className={className}>
                    Reservationer
                </NavigationItem>
                <NavigationItem id="dcb-history" to="/dcb/history" onClick={onNavigationClick} className={className}>
                    Historik
                </NavigationItem>
            </React.Fragment>
        )}
    </ComposeNavigationItems>
));

export default DCBNavigationItems;
