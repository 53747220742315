/**
 * Creates a wrapper function around the XMLHttpRequest API that provides
 * default arguments & headers and is intended to reduce the amount
 * of boilerplate code in the application.
 */
function createXHR({ baseUrl }) {
    // NOTE: Tweak the default options to suite your application needs

    const defaultHeaders = {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json'
    };

    const multipartHeaders = {
        'X-Requested-With': 'XMLHttpRequest'
    };

    const defaults = {
        method: 'POST'
    };

    return (url, options, multipart, onProgress) => {
        const xhr = new XMLHttpRequest();

        return {
            xhr,
            promiseWrapper: new Promise((resolve, reject) => {
                xhr.open(options.method || defaults.method, `${baseUrl}${url}`);

                const headers = {
                    ...(multipart ? multipartHeaders : defaultHeaders),
                    ...(options && options.headers)
                };

                Object.keys(headers).forEach(header => xhr.setRequestHeader(header, headers[header]));

                xhr.onabort = () => {
                    reject(new Error('499')); // Client Closed Request
                };

                xhr.onload = e => {
                    resolve({
                        response: e.target.responseText,
                        status: e.target.status,
                        json: () => JSON.parse(e.target.responseText)
                    });
                };

                xhr.onerror = e => {
                    reject(new Error(`XHR request failed with error: ${e}`));
                };

                if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress;

                xhr.send(options.body);
            })
        };
    };
}

export default createXHR;
