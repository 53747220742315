import { GlobalContextProps, RootState } from 'state/types';
import { useSelector } from 'react-redux';

type Action = GlobalContextProps['iua']['permissions'][0]['action'];
type Target = GlobalContextProps['iua']['permissions'][0]['target'];

const useHasActionPermission = (action: Action, target: Target): boolean => {
    // Let's refactor this hook to not use the global context
    // and instead useRedux hooks to get the iua state
    const { permissions } = useSelector((state: RootState) => state.iua) || {};

    return permissions
        ? permissions.some(permission => permission.action === action && permission.target === target)
        : false;
};

export default useHasActionPermission;
