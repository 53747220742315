import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { pulseViewFrontPage, pulseViewPage } from 'core/pulse';
import { pageview } from 'state/general/actions';

const sendEvent = (pathname: string) => (pathname === '/' ? pulseViewFrontPage() : pulseViewPage());

const PageViewComponent = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(pageview(pathname));
        sendEvent(pathname);
    }, [pathname, dispatch]);

    return null;
};

export default PageViewComponent;
