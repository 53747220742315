import React, { useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';

import pendingOrdersQuery from './queries/pendingOrders';
import updateOrderMutation from './mutations/updateOrder';

import Spring from './Spring';

interface Props {
    dealerCode: string;
}

const DCBOrders = ({ dealerCode }: Props) => {
    const POLL_INTERVALL = 60000;

    const dispatch = useDispatch();

    const [updateOrder] = useMutation(updateOrderMutation);

    const onComplete = async pendingOrders => {
        const { count, orders } = pendingOrders;

        try {
            // Set all orders with status "new" to "pending"
            await Promise.all(orders.map(({ id }) => id && updateOrder({ variables: { id: parseInt(id, 10) } })));
        } catch (e) {
            console.error(e);
        }

        // Store the query results in our redux store
        dispatch({ type: '@DCB/SET_ORDERS', numPendingOrders: count, orders });
    };

    const { startPolling, stopPolling } = useQuery(pendingOrdersQuery, {
        variables: {
            dealerCode
        },
        fetchPolicy: 'no-cache',
        pollInterval: POLL_INTERVALL,
        notifyOnNetworkStatusChange: true,
        onCompleted: ({ pendingOrders }) => {
            onComplete(pendingOrders);
        }
    });

    // User has switched back to the tab
    const onFocus = () => {
        startPolling(POLL_INTERVALL);
    };

    // User has switched away from the tab
    const onBlur = () => {
        stopPolling();
    };

    useEffect(() => {
        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);
    });

    return <Spring />;
};

export default DCBOrders;
