export const createReducer =
    (intialState, handlers) =>
    (state = intialState, action) =>
        Object.prototype.hasOwnProperty.call(handlers, action.type) ? handlers[action.type](state, action) : state;

export const createIsFetching = ({ REQUEST, RESPONSE, ERROR }) => {
    const defaultState = { isFetching: false, isError: false, data: undefined };
    return createReducer(defaultState, {
        [ERROR]: (state, action) => ({ ...defaultState, isError: true, error: action.error }),
        [REQUEST]: () => ({ ...defaultState, isFetching: true }),
        [RESPONSE]: (state, action) => ({ ...defaultState, data: action.response })
    });
};
