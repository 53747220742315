export const BUMP_RECOMMENDATIONS_REQUEST = '@BUMP_RECOMMENDATIONS/BUMP_RECOMMENDATIONS_REQUEST';
export const BUMP_RECOMMENDATIONS_RESPONSE = '@BUMP_RECOMMENDATIONS/BUMP_RECOMMENDATIONS_RESPONSE';
export const BUMP_RECOMMENDATIONS_ERROR = '@BUMP_RECOMMENDATIONS/BUMP_RECOMMENDATIONS_ERROR';

export const BUMP_FILTERS_REQUEST = '@BUMP_RECOMMENDATIONS/BUMP_FILTERS_REQUEST';
export const BUMP_FILTERS_RESPONSE = '@BUMP_RECOMMENDATIONS/BUMP_FILTERS_RESPONSE';
export const BUMP_FILTERS_ERROR = '@BUMP_RECOMMENDATIONS/BUMP_FILTERS_ERROR';

export const SET_BUMP_FILTER_REQUEST = '@BUMP_RECOMMENDATIONS/SET_BUMP_FILTER_REQUEST';
export const SET_BUMP_FILTER_RESPONSE = '@BUMP_RECOMMENDATIONS/SET_BUMP_FILTER_RESPONSE';
export const SET_BUMP_FILTER_ERROR = '@BUMP_RECOMMENDATIONS/SET_BUMP_FILTER_ERROR';

export const UPDATE_BUMP_FILTER_REQUEST = '@BUMP_RECOMMENDATIONS/UPDATE_BUMP_FILTER_REQUEST';
export const UPDATE_BUMP_FILTER_RESPONSE = '@BUMP_RECOMMENDATIONS/UPDATE_BUMP_FILTER_RESPONSE';
export const UPDATE_BUMP_FILTER_ERROR = '@BUMP_RECOMMENDATIONS/UPDATE_BUMP_FILTER_ERROR';

export const DELETE_BUMP_FILTER_REQUEST = '@BUMP_RECOMMENDATIONS/DELETE_BUMP_FILTER_REQUEST';
export const DELETE_BUMP_FILTER_RESPONSE = '@BUMP_RECOMMENDATIONS/DELETE_BUMP_FILTER_RESPONSE';
export const DELETE_BUMP_FILTER_ERROR = '@BUMP_RECOMMENDATIONS/DELETE_BUMP_FILTER_ERROR';

export const BUMP_HISTORY_REQUEST = '@BUMP_RECOMMENDATIONS/BUMP_HISTORY_REQUEST';
export const BUMP_HISTORY_RESPONSE = '@BUMP_RECOMMENDATIONS/BUMP_HISTORY_RESPONSE';
export const BUMP_HISTORY_ERROR = '@BUMP_RECOMMENDATIONS/BUMP_HISTORY_ERROR';

export const OPEN_CREATE_BUMP_FILTER_FORM = '@BUMP_RECOMMENDATIONS/OPEN_CREATE_BUMP_FILTER_FORM';
export const OPEN_BUMP_FILTER_SETTINGS = '@BUMP_RECOMMENDATIONS/OPEN_BUMP_FILTER_SETTINGS';
