import React from 'react';
import { ReactReduxContext } from 'react-redux';
import { navigateTo } from '../../core/navigationHandler';

interface Props {
    to: string;
    children: React.ReactNode;
    legacy: boolean | object;
    className?: string;
    style?: React.CSSProperties;
}

interface FilteredProps {
    target?: string;
    rel?: string;
}

class LegacyLink extends React.Component<Props> {
    static contextType = ReactReduxContext;

    context!: React.ContextType<typeof ReactReduxContext>;

    legacyNavigationHandler = event => {
        const { to, legacy } = this.props;
        const { store } = this.context;

        event.preventDefault();

        navigateTo(store.getState(), to, legacy);
    };

    render() {
        const { to, children, ...props } = this.props;
        // Only set the attribute if it is a valid attribute
        // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a
        const allowedProps = {
            className: true,
            download: true,
            hreflang: true,
            referrerpolicy: true,
            target: true,
            title: true,
            type: true
        };
        const filteredProps: FilteredProps = {};

        Object.keys(props).forEach(prop => {
            if (props[prop] && allowedProps[prop]) {
                filteredProps[prop] = props[prop];
            }
        });

        if (filteredProps.target && filteredProps.target === '_blank') {
            filteredProps.rel = 'noopener noreferrer';
        }

        return (
            <a href={to} {...filteredProps} onClick={this.legacyNavigationHandler}>
                {children}
            </a>
        );
    }
}

export default LegacyLink;
