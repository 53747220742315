import React from 'react';

import NavigationItem from 'components/Navigation/NavigationItem';
import ComposeNavigationItems from './ComposeNavigationItems';

interface Props {
    className?: string;
}

const DealerNavigationItems = React.memo(({ className }: Props) => (
    <ComposeNavigationItems origin="header">
        {({ onNavigationClick, onCustomerServiceClick }) => (
            <React.Fragment>
                <NavigationItem
                    id="customerservice-page"
                    to="https://blocket.zendesk.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onNavigationClick}
                    className={className}
                >
                    Besök vår kundservice-sida
                </NavigationItem>
                <NavigationItem
                    id="customerservice-modal"
                    onClick={onCustomerServiceClick}
                    to="#customer-service__modal"
                    className={className}
                >
                    Kontakta kundservice
                </NavigationItem>
            </React.Fragment>
        )}
    </ComposeNavigationItems>
));

export default DealerNavigationItems;
