export const defaultState = {
    data: {
        stock: {
            active: undefined,
            hidden: undefined
        },
        marketValue: undefined,
        averageTime: undefined
    }
};

/**
 * Dealer reducer
 */
export default function dashboard(state = defaultState, action) {
    switch (action.type) {
        default:
            return state;
    }
}
