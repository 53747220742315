import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/types';

import hasProduct from 'core/hasProduct';

import NewDCBOrders from './NewDCBOrders';

// Right now we are just sending notifications for new DCB orders but who knows
// what the future will have in store...
const SpringNotifications = () => {
    const { code: dealerCode, products } = useSelector((state: RootState) => state.dealer.dealerApi);

    if (dealerCode && hasProduct({ products, productCode: 'dcb' })) {
        return <NewDCBOrders dealerCode={dealerCode} />;
    }
    return null;
};

export default SpringNotifications;
