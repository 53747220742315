import { Product } from 'state/types';

interface Props {
    products: Product[];
    productCode: string;
}

const hasProduct = ({ products, productCode }: Props) => !!products.find(({ code }) => code === productCode);

export default hasProduct;
