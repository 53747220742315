import gql from 'graphql-tag';

export interface Product {
    code: string;
    name: string;
    default: boolean;
    settings?: any;
}

export default gql`
    query GetDealerById($id: Int!) {
        dealerById(where: { id: $id }) {
            id
            name
            description
            code
            created

            phoneNumber
            website

            orgNumber
            visitingAddress
            postalNumber
            postAddress
            city
            county

            emailDisplay
            emailLead
            emailImport
            emailExport

            deliveryDescription

            products {
                name
                code
                settings
            }

            tags {
                tag
            }

            groups {
                id
                code
                name
                admin
            }

            groupMembers {
                id
                code
                name
            }

            marketplaces {
                marketplace
                url
            }

            authorizedBrands
        }
    }
`;
