import * as IUA_USER from './actionTypes';

export const defaultAuthState = {
    authenticated: false,
    dealers: [],
    dealersData: [],
    permissions: [],
    user: {},
    checksum: ''
};

export function iua(state = defaultAuthState, action) {
    switch (action.type) {
        // Set user authenticated / unauthenticated
        case IUA_USER.SET_AUTHENTICATED:
            return {
                ...state,
                authenticated: true,
                permissions: action.data.permissions,
                dealers: action.data.dealers,
                dealersData: action.data.dealersData,
                user: action.data.user
            };

        case IUA_USER.SET_UNAUTHENTICATED:
            return defaultAuthState;

        case IUA_USER.SET_USER_DATA:
            return {
                ...state,
                user: action.data
            };

        case IUA_USER.UPDATE_CHECKSUM:
            return {
                ...state,
                checksum: action.checksum
            };

        default:
            return state;
    }
}

export default iua;
