import universalFetch from 'core/universalFetch';

/**
 * Creates a wrapper function around the XMLHttpRequest API that provides
 * default arguments & headers and is intended to reduce the amount
 * of boilerplate code in the application.
 */

const fetch = universalFetch(window.fetch, {
    baseUrl: ''
});

const parseHeaders = headers => {
    // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/getAllResponseHeaders
    const arr = headers.trim().split(/[\r\n]+/);

    const headerMap = {
        // eslint-disable-next-line object-shorthand, func-names
        get: function (property) {
            return this[property] || this[property.toLowerCase()] || '';
        }
    };
    arr.forEach(line => {
        const parts = line.split(': ');
        const header = parts.shift();
        const value = parts.join(': ');
        headerMap[header] = value;
    });
    return headerMap;
};

function customFetch(url, options) {
    if (!options.xhr) {
        return fetch(url, options);
    }

    const defaultHeaders = {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json'
    };

    const multipartHeaders = {
        'X-Requested-With': 'XMLHttpRequest'
    };

    const defaults = {
        method: 'POST'
    };

    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.open(options.method || defaults.method, url);

        const headers = {
            ...(options.multipart ? multipartHeaders : defaultHeaders),
            ...(options && options.headers)
        };

        Object.keys(headers).forEach(header => xhr.setRequestHeader(header, headers[header]));

        xhr.onabort = () => {
            reject(new Error('499')); // Client Closed Request
        };

        xhr.onload = () => {
            const opts = {
                status: xhr.status,
                statusText: xhr.statusText
            };
            opts.url =
                'responseURL' in xhr
                    ? xhr.responseURL
                    : parseHeaders(xhr.getAllResponseHeaders() || '').get('X-Request-URL');
            const body = 'response' in xhr ? xhr.response : xhr.responseText;
            resolve(new Response(body, opts));
        };

        xhr.onerror = e => {
            reject(new Error(`XHR request failed with error: ${e}`));
        };

        if (xhr.upload && options.onProgress) xhr.upload.onprogress = options.onProgress;

        xhr.send(options.body);
    });
}

export default customFetch;
