import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'state/types';

import { Navbar } from '@bytbil/bootstrap-react';
import classNames from 'classnames';
import TextTruncate from 'components/Text/Truncate';
import DealerDropdown from 'components/DealerDropdown';
import UserDropdown from 'components/UserDropdown';
import Logotype from 'components/Logotype';

import HeaderNavigationItems from './Navigation/HeaderNavigationItems';
import ChatBot from './ChatBot';

interface User {
    login: string;
    level: number;
}

interface Props {
    offcanvas: boolean;
    toggleDealerDropdown: () => void;
    toggleUserDropdown: () => void;
    setDealer: (dealerId: number) => void;
    isAuthed: boolean;

    userDropdown: boolean;
    dealerDropdown: boolean;
    user: User;
    logoClick: () => void;
    showCustomerServiceModal: (e: React.SyntheticEvent) => void;
}

const Header = ({
    offcanvas,
    toggleDealerDropdown,
    toggleUserDropdown,
    setDealer,
    isAuthed,

    userDropdown,
    dealerDropdown,
    user,
    logoClick,
    showCustomerServiceModal
}: Props) => {
    const { code: dealerCode, groupMembers } = useSelector((state: RootState) => state.dealer.dealerApi) || {};

    const { authenticated: isIUAAuthenticated, dealersData: iuaDealers } =
        useSelector((state: RootState) => state.iua) || {};

    // Previously, this just check isAuthed from auth-api ... but now we also need to check IUA
    const isAuthedByEither = isIUAAuthenticated || isAuthed;

    /**
     * This should resolve to true based on two conditions:
     * 1. The user is a auth-api user and is part of a group with more than one dealer
     * 2. The user is a IUA user and has access to more than one dealer
     * */
    const shouldRenderDealerDropdown =
        (user && user.level > 0 && groupMembers && groupMembers.length > 1) || (iuaDealers && iuaDealers.length > 1);

    return (
        <Navbar expand="lg" tag="header" className="bg-dark-alt px-0 header-main d-none d-xl-flex" dark>
            <div className="container d-flex justify-content-between w-100">
                <div className="d-flex align-items-center flex-row flex-grow">
                    <Logotype
                        className={offcanvas && groupMembers && groupMembers.length <= 1 ? 'header-logo-above' : ''}
                        onClick={logoClick}
                    />
                    <div className="flex-grow">
                        <ul
                            className={classNames(
                                'd-flex flex-row navbar-nav ml-4',
                                !isAuthedByEither ? 'justify-content-end' : 'd-none d-xl-flex'
                            )}
                        >
                            <HeaderNavigationItems />
                        </ul>
                    </div>
                </div>
                {isAuthedByEither ? (
                    <div className="d-flex align-items-center">
                        <ul className="d-flex flex-row navbar-nav">
                            <li className="nav-item d-flex align-items-center">
                                <ChatBot />
                            </li>
                            {shouldRenderDealerDropdown && (
                                <li className={classNames('nav-item', isIUAAuthenticated && 'align-self-center')}>
                                    <DealerDropdown
                                        isOpen={dealerDropdown}
                                        toggle={toggleDealerDropdown}
                                        setDealer={setDealer}
                                        align="right"
                                    />
                                </li>
                            )}
                            {!isIUAAuthenticated && groupMembers && groupMembers.length <= 1 && (
                                <li className="nav-item">
                                    <div className="fs-3 text-white">
                                        <strong className="nav-link no-hover">
                                            <span data-hj-suppress>
                                                <TextTruncate limit={11}>{dealerCode}</TextTruncate>
                                            </span>
                                        </strong>
                                    </div>
                                </li>
                            )}
                            <li className="nav-item">
                                <UserDropdown
                                    isUserDropDownOpen={userDropdown}
                                    toggleUserDropDown={toggleUserDropdown}
                                    showCustomerServiceModal={showCustomerServiceModal}
                                />
                            </li>
                        </ul>
                    </div>
                ) : (
                    <div>Laddar...</div>
                )}
            </div>
        </Navbar>
    );
};

export default Header;
