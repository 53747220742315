export const PRICING_GET_VEHICLE_REQUEST = '@PRICING/PRICING_GET_VEHICLE_REQUEST';
export const PRICING_GET_VEHICLE_RESPONSE = '@PRICING/PRICING_GET_VEHICLE_RESPONSE';
export const PRICING_GET_VEHICLE_ERROR = '@PRICING/PRICING_GET_VEHICLE_ERROR';

export const PRICING_GET_VEHICLE_COMPARE_REQUEST = '@PRICING/PRICING_GET_VEHICLE_COMPARE_REQUEST';
export const PRICING_GET_VEHICLE_COMPARE_RESPONSE = '@PRICING/PRICING_GET_VEHICLE_COMPARE_RESPONSE';
export const PRICING_GET_VEHICLE_COMPARE_ERROR = '@PRICING/PRICING_GET_VEHICLE_COMPARE_ERROR';

export const PRICING_GET_CLASSIFIEDS_REQUEST = '@PRICING/PRICING_GET_CLASSIFIEDS_REQUEST';
export const PRICING_GET_CLASSIFIEDS_RESPONSE = '@PRICING/PRICING_GET_CLASSIFIEDS_RESPONSE';
export const PRICING_GET_CLASSIFIEDS_ERROR = '@PRICING/PRICING_GET_CLASSIFIEDS_ERROR';

export const PRICING_SET_SORT_SETTINGS = '@PRICING/SET_SORT_SETTINGS';
export const PRICING_RESET_SORT_SETTINGS = '@PRICING/RESET_SORT_SETTINGS';
export const PRICING_SET_FILTER_SETTINGS = '@PRICING/SET_FILTER_SETTINGS';
export const PRICING_RESET_FILTER_SETTINGS = '@PRICING/RESET_FILTER_SETTINGS';
export const PRICING_UPDATE_FILTER_SETTINGS = '@PRICING/UPDATE_FILTER_SETTINGS';
export const PRICING_RESET = '@PRICING/PRICING_RESET';
export const PRICING_RESET_SEARCH_CLICK = '@PRICING/PRICING_RESET_SEARCH_CLICK';
export const PRICING_COMPARE_RESET = '@PRICING/PRICING_COMPARE_RESET';
export const SHOW_EXTRA_EQUIPMENT = '@PRICING/SHOW_EXTRA_EQUIPMENT';
export const PRICING_ON_AD_LINK_CLICK = '@PRICING/PRICING_ON_AD_LINK_CLICK';
