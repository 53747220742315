import { EQUIPMENTGROUP_SET_DIRTY } from './actionTypes';

const defaultState = {
    dirty: false
};

const equipmentGroupReducer = (state = defaultState, action: { type: string; payload: boolean }) => {
    switch (action.type) {
        case EQUIPMENTGROUP_SET_DIRTY:
            return {
                ...state,
                dirty: action.payload
            };

        default:
            return state;
    }
};

export default equipmentGroupReducer;
