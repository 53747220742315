import React from 'react';
import { useSelector } from 'react-redux';

import NavigationItem from 'components/Navigation/NavigationItem';
import { RootState } from 'state/types';
import hasTag from 'core/hasTag';
import ComposeNavigationItems from './ComposeNavigationItems';

interface Props {
    className?: string;
}

const StatisticsNavigationItems = ({ className }: Props) => {
    const { tags } = useSelector((state: RootState) => state.dealer.dealerApi);

    return (
        <ComposeNavigationItems origin="header">
            {({ onNavigationClick }) => (
                <React.Fragment>
                    <NavigationItem
                        id="statistics-dashboard"
                        to="/statistics"
                        onClick={onNavigationClick}
                        className={className}
                    >
                        Dashboard
                    </NavigationItem>
                    {hasTag({ tags, tagName: 'new_statistics_tester' }) && (
                        <NavigationItem
                            id="statistics-active-ads"
                            to="/statistics/active-ads"
                            onClick={onNavigationClick}
                            className={className}
                        >
                            Aktiva annonser
                        </NavigationItem>
                    )}
                </React.Fragment>
            )}
        </ComposeNavigationItems>
    );
};

export default StatisticsNavigationItems;
