import { combineReducers } from 'redux';
import * as actionTypes from './actionTypes';

const vehicleTypeReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.SET_VEHICLE_TYPE:
            return {
                ...state,
                name: action.name,
                id: action.id
            };
        case actionTypes.RESET_VEHICLE_TYPE:
            return {
                state: {}
            };
        default:
            return state;
    }
};

const vehicleDataReducer = (state = null, action) => {
    switch (action.type) {
        case actionTypes.VEHICLE_DATA_POPOVER_ACCEPT:
            return {
                ...state,
                ...action.vehicleData
            };
        case actionTypes.VEHICLE_DATA_POPOVER_REJECT:
            return {
                ...state,
                vehicleData: action.vehicleData
            };
        case actionTypes.VEHICLE_DATA_POPOVER_DISMISS:
            return {
                ...state,
                vehicleData: action.vehicleData
            };
        case actionTypes.COPY_OFFER_CLICK:
            return {
                ...state,
                vehicleData: action.vehicleData
            };
        case actionTypes.RESET_VEHICLE_DATA:
            return {
                state: null
            };
        default:
            return state;
    }
};

const offerDataReducer = (
    state = { dirty: false, formValues: undefined, showDraftValues: false, regNoTaken: false, mode: undefined },
    action
) => {
    switch (action.type) {
        case actionTypes.SET_OFFER_FORM_DIRTY:
            return {
                ...state,
                dirty: action.dirty
            };
        case actionTypes.SET_OFFER_FORM_DRAFT_VALUES:
            return {
                ...state,
                formValues: action.formValues
            };
        case actionTypes.SET_OFFER_FORM_SHOW_DRAFT_VALUES:
            return {
                ...state,
                showDraftValues: action.showDraftValues
            };
        case actionTypes.SET_MODE:
            return {
                ...state,
                mode: action.mode
            };
        default:
            return state;
    }
};

export default combineReducers({
    vehicleType: vehicleTypeReducer,
    vehicleData: vehicleDataReducer,
    offerData: offerDataReducer
});
