import React from 'react';
import { GlobalContext } from 'context/GlobalContext';
import NavigationLink from '../NavigationLink';

interface Props {
    className?: string;
    onClick: () => void;
}

const Logotype = ({ className = '', onClick }: Props) => (
    <GlobalContext.Consumer>
        {({ cdnUrl }) => (
            <NavigationLink to="/" className={`navbar-brand ${className}`} onClick={onClick} dataTestId="navbar-brand">
                <img
                    src={`${cdnUrl || ''}/img/blocket-admin-logo.svg`}
                    className="navbar-brand-logo"
                    alt="Blocket Fordon Logotype"
                />
            </NavigationLink>
        )}
    </GlobalContext.Consumer>
);

export default Logotype;
