import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'state/types';

import { Icon } from '@bytbil/general-react';

import hasProduct from 'core/hasProduct';

import NavigationItem from 'components/Navigation/NavigationItem';
import ComposeNavigationItems from './ComposeNavigationItems';

import PendingDCBOrdersCount from '../PendingDCBOrdersCount';

const HeaderNavigationItems = () => {
    const { pathname } = useLocation();
    const { products } = useSelector((state: RootState) => state.dealer.dealerApi);

    const vehicleSubNavUrls = [
        '/vehicles',
        '/vehicles/history',
        '/bumpsettings',
        '/prices',
        '/edit-regno',
        '/import-status',
        '/move-stock'
    ];

    return (
        <ComposeNavigationItems origin="header">
            {({ onNavigationClick }) => (
                <React.Fragment>
                    <NavigationItem
                        id="offer"
                        to="/offer"
                        onClick={onNavigationClick}
                        active={pathname.startsWith('/offer')}
                    >
                        <Icon name="plus" className="pr-1 pr-md-2" />
                        Nytt fordon
                    </NavigationItem>
                    <NavigationItem
                        id="vehicles"
                        to="/vehicles"
                        onClick={onNavigationClick}
                        active={vehicleSubNavUrls.includes(pathname)}
                    >
                        <Icon name="car" className="pr-2 pr-md-2" />
                        Fordon
                    </NavigationItem>
                    <NavigationItem
                        id="statistics"
                        to="/statistics"
                        onClick={onNavigationClick}
                        active={pathname.startsWith('/statistics')}
                    >
                        Statistik
                    </NavigationItem>
                    <NavigationItem
                        id="dealer"
                        to="/dealer/edit"
                        onClick={onNavigationClick}
                        active={pathname.startsWith('/dealer/')}
                    >
                        Anläggning
                    </NavigationItem>
                    {hasProduct({ products, productCode: 'dcb' }) && (
                        <NavigationItem
                            id="dcb-orders"
                            to="/dcb/orders"
                            onClick={onNavigationClick}
                            active={pathname.startsWith('/dcb')}
                        >
                            Reservationer <PendingDCBOrdersCount />
                        </NavigationItem>
                    )}
                    <NavigationItem id="pricing" to="/pricing" onClick={onNavigationClick}>
                        Priskollen
                        <div className="fs-1 navbar-pro-logo">PRO</div>
                    </NavigationItem>
                </React.Fragment>
            )}
        </ComposeNavigationItems>
    );
};

export default HeaderNavigationItems;
