import React from 'react';

import useHasActionPermission from 'core/hooks/useHasActionPermission';

import { GlobalContextProps, RootState } from 'state/types';
import { useSelector } from 'react-redux';

type Action = GlobalContextProps['iua']['permissions'][0]['action'];
type Target = GlobalContextProps['iua']['permissions'][0]['target'];

interface Props {
    action: Action;
    target: Target;
    children: React.ReactElement | null;
    showNoPermissionsMessage?: boolean;
}

const IUAPermissionsProvider = ({ action, target, children, showNoPermissionsMessage = false }: Props) => {
    // Need to check if this is an auth-api user first
    const { authenticated: isIUAAuthenticated } = useSelector((state: RootState) => state.iua) || {};

    if (useHasActionPermission(action, target)) {
        return children;
    }

    // While we still have auth-api:
    // auth-api doesn't care about permissions
    // so if the user isn't iua authenticated, just pass the children through
    if (!isIUAAuthenticated) {
        return children;
    }

    if (showNoPermissionsMessage) {
        return <p className="container mt-2">Du har inte behörighet att utföra denna åtgärd.</p>;
    }

    return null;
};

export default IUAPermissionsProvider;
