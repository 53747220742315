import * as GENERAL from './actionTypes';

export const pageview = location => ({
    type: GENERAL.PAGEVIEW,
    location
});

export const logo = () => ({
    type: GENERAL.BYTBIL_LOGO_CLICK
});

export const navigationClick = (target, origin) => ({
    type: GENERAL.NAVIGATION_CLICK,
    target,
    origin
});

export const terms = () => ({
    type: GENERAL.TERMS_CLICK
});

export const helpdesk = origin => ({
    type: GENERAL.CUSTOMERSERVICE_LINK_CLICK,
    origin
});
