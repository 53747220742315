import React from 'react';
import { Navbar } from '@bytbil/bootstrap-react';
import classNames from 'classnames';
import NavigationOffcanvas from './Navigation/NavigationOffcanvas';

interface OffcanvasCollapse {
    id: string;
    show: boolean;
    isSubsectionTo?: string;
}
interface Props {
    offcanvas: boolean;
    toggleOffcanvasCollapse?: (id: string, show: boolean, isSubsectionTo?: string) => void;
    offcanvasCollapse?: OffcanvasCollapse;
}

const MobileOffcanvas = React.memo(({ offcanvas, toggleOffcanvasCollapse, offcanvasCollapse }: Props) => (
    <Navbar
        className={classNames(
            'navbar-mobile-menu',
            'd-xl-none',
            'justify-content-end',
            'navbar-panel',
            'panel-lg',
            'fade-in',
            'bg-dark-alt',
            'p-0',
            offcanvas && 'show'
        )}
        id="navbar-nav"
        role="navigation"
        aria-expanded="false"
        suppressHydrationWarning
    >
        <div className="navbar-mobile-scroll">
            <NavigationOffcanvas
                toggleOffcanvasCollapse={toggleOffcanvasCollapse}
                offcanvasCollapse={offcanvasCollapse}
                className="d-flex navbar-nav navbar-items fs-4"
            />
        </div>
    </Navbar>
));

export default MobileOffcanvas;
