import produce from 'immer';
import { createIsFetching } from 'store/reducers/fetch';
import { combineReducers } from 'redux';
import * as PRICING from './actionTypes';

const defaultFetchState = { isFetching: false, isError: false, data: undefined };

const defaultState = {
    sortSettings: {
        sortBy: 'price',
        sortDirection: 'ASC'
    },
    showExtraEquipment: false,
    filterSettings: {},
    vehicleData: defaultFetchState,
    classifieds: defaultFetchState,
    comparisonData: defaultFetchState
};

const settingsReducer =
    (type, resetType, defaultSettingsState) =>
    (state = defaultSettingsState, action) => {
        if (action.type === type) {
            return produce(state, draft => {
                Object.keys(action.payload).forEach(key => {
                    draft[key] = action.payload[key];
                });
            });
        }

        if (action.type === resetType) {
            return defaultSettingsState;
        }

        return state;
    };

export default (state, action) => {
    const intermediateState = action.type === PRICING.PRICING_RESET ? defaultState : state;

    return combineReducers({
        showExtraEquipment: (extraEquipmentState = false, extraEquipmentAction) => {
            if (extraEquipmentAction.type === PRICING.SHOW_EXTRA_EQUIPMENT) {
                return action.payload;
            }

            return extraEquipmentState;
        },
        sortSettings: settingsReducer(
            PRICING.PRICING_SET_SORT_SETTINGS,
            PRICING.PRICING_RESET_SORT_SETTINGS,
            defaultState.sortSettings
        ),
        filterSettings: settingsReducer(
            PRICING.PRICING_SET_FILTER_SETTINGS,
            PRICING.PRICING_RESET_FILTER_SETTINGS,
            defaultState.filterSettings
        ),
        vehicleData: createIsFetching({
            ERROR: PRICING.PRICING_GET_VEHICLE_ERROR,
            REQUEST: PRICING.PRICING_GET_VEHICLE_REQUEST,
            RESPONSE: PRICING.PRICING_GET_VEHICLE_RESPONSE
        }),
        classifieds: (classifiedsState, classifiedsAction) => {
            const fetchState = createIsFetching({
                ERROR: PRICING.PRICING_GET_CLASSIFIEDS_ERROR,
                REQUEST: PRICING.PRICING_GET_CLASSIFIEDS_REQUEST,
                RESPONSE: PRICING.PRICING_GET_CLASSIFIEDS_RESPONSE
            })(classifiedsState, classifiedsAction);

            // Keep data until we get new data (better experience when changing filters)
            if (action.type === PRICING.PRICING_GET_CLASSIFIEDS_REQUEST) {
                return { ...fetchState, data: state.classifieds.data };
            }

            return fetchState;
        },
        comparisonData: (compareState, compareAction) => {
            if (action.type === PRICING.PRICING_COMPARE_RESET) {
                return defaultState.comparisonData;
            }

            return createIsFetching({
                ERROR: PRICING.PRICING_GET_VEHICLE_COMPARE_ERROR,
                REQUEST: PRICING.PRICING_GET_VEHICLE_COMPARE_REQUEST,
                RESPONSE: PRICING.PRICING_GET_VEHICLE_COMPARE_RESPONSE
            })(compareState, compareAction);
        }
    })(intermediateState, action);
};
