import * as DEALERS from './actionTypes';

export const defaultState = {
    status: {
        isFetching: false,
        hasData: false
    },
    error: {
        status: false,
        type: undefined
    },
    filterSettings: {},
    dealerItems: [],
    dealerGroupId: undefined
};

const dealers = (state = defaultState, action) => {
    switch (action.type) {
        case DEALERS.GET_DEALERS_REQUEST:
            return {
                ...state,
                status: {
                    isFetching: true,
                    hasData: false
                },
                error: {
                    status: false,
                    type: undefined
                }
            };

        case DEALERS.GET_DEALERS_RESPONSE:
            return {
                ...state,
                status: {
                    isFetching: false,
                    hasData: false
                },
                error: {
                    status: false,
                    type: undefined
                },
                dealerItems: action.response.dealers,
                filterSettings: {
                    limits: action.response.limits
                },
                dealerGroupId: action.response.dealerGroupId
            };

        case DEALERS.GET_DEALERS_ERROR:
            return {
                ...state,
                status: {
                    isFetching: false,
                    hasData: false
                },
                error: {
                    status: true,
                    type: action.error
                }
            };

        default:
            return state;
    }
};

export default dealers;
