import React from 'react';

import NavigationItem from 'components/Navigation/NavigationItem';
import ComposeNavigationItems from './ComposeNavigationItems';
import ChatBot from '../ChatBot';

const HeaderNavigationItems = React.memo(() => (
    <ComposeNavigationItems origin="header">
        {({ onNavigationClick }) => (
            <React.Fragment>
                <li className="nav-item d-flex align-items-center">
                    <ChatBot />
                </li>
                <NavigationItem id="offer" to="/offer" className="px-2 px-md-3" onClick={onNavigationClick}>
                    Nytt fordon
                </NavigationItem>
                <NavigationItem id="vehicles" to="/vehicles" className="px-2 px-md-3" onClick={onNavigationClick}>
                    Fordon
                </NavigationItem>
            </React.Fragment>
        )}
    </ComposeNavigationItems>
));

export default HeaderNavigationItems;
