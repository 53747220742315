import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/types';
import CollapseItem from './CollapseItem';

interface OffcanvasCollapse {
    id: string;
    show: boolean;
    isSubsectionTo?: string;
}

interface Props {
    id: string;
    active?: boolean;
    isPublic?: boolean;
    toggleOffcanvasCollapse?: (id: string, show: boolean, isSubsectionTo?: string) => void;
    offcanvasCollapse?: OffcanvasCollapse;
    children: React.ReactNode[];
    isSubsectionTo?: string;
}

const SubNavigationItems = ({
    active = false,
    isPublic = false,

    isSubsectionTo = undefined,
    id,
    toggleOffcanvasCollapse,
    offcanvasCollapse,
    children
}: Props) => {
    const { authenticated: isAuthenticated } = useSelector((state: RootState) => state.user.auth) || {};

    if (!isPublic && !isAuthenticated) {
        return null;
    }

    return (
        <div className="nav-item-parent" key={`collapse-item-${id}`}>
            <CollapseItem
                id={id}
                active={active}
                toggleOffcanvasCollapse={toggleOffcanvasCollapse}
                offcanvasCollapse={offcanvasCollapse}
                isSubsectionTo={isSubsectionTo}
            >
                {children}
            </CollapseItem>
        </div>
    );
};

// eslint-disable-next-line react/prop-types
SubNavigationItems.Toggle = ({ children, ...props }) => (
    <CollapseItem.Toggle {...props}>{children}</CollapseItem.Toggle>
);

// eslint-disable-next-line react/prop-types
SubNavigationItems.Items = ({ children, ...props }) => <CollapseItem.Items {...props}>{children}</CollapseItem.Items>;

export default SubNavigationItems;
