import React from 'react';
import { Collapse } from '@bytbil/bootstrap-react';
import classNames from 'classnames';

interface OffcanvasCollapse {
    id: string;
    show: boolean;
    isSubsectionTo?: string;
}

interface SubComponents {
    Toggle?: () => void;
    Items?: React.ReactNode[];
}

interface Props extends SubComponents {
    id: string;
    active?: boolean;
    children: React.ReactNode[];
    toggleOffcanvasCollapse?: (id: string, show: boolean, isSubsectionTo?: string) => void;
    offcanvasCollapse?: OffcanvasCollapse;
    isSubsectionTo?: string;
}

class CollapseItem extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.isOpen = this.isOpen.bind(this);
    }

    static Items;

    static Toggle;

    isOpen() {
        const { offcanvasCollapse, active, id } = this.props;

        // When toggling subsection, parent section must stay open
        if (offcanvasCollapse && offcanvasCollapse.isSubsectionTo === id) {
            return true;
        }

        if (active && offcanvasCollapse && typeof offcanvasCollapse.id === 'undefined') {
            return true;
        }

        if (offcanvasCollapse && offcanvasCollapse.id === id && offcanvasCollapse.show) {
            return true;
        }

        return false;
    }

    toggle(toggleId) {
        const { offcanvasCollapse, toggleOffcanvasCollapse, active, id, isSubsectionTo } = this.props;
        let show = true;

        if (offcanvasCollapse && offcanvasCollapse.id === id) {
            show = !offcanvasCollapse.show;
        }
        if (offcanvasCollapse && offcanvasCollapse.isSubsectionTo === id) {
            show = false;
        }
        if (active && offcanvasCollapse && typeof offcanvasCollapse.id === 'undefined') {
            show = false;
        }

        if (toggleOffcanvasCollapse) {
            toggleOffcanvasCollapse(toggleId, show, isSubsectionTo);
        }
    }

    render() {
        const { id, children, active } = this.props;

        return (
            <React.Fragment>
                <li className="nav-item d-xl-none">
                    <div
                        onClick={e => {
                            e.preventDefault();
                            this.toggle(id);
                        }}
                        role="button"
                        tabIndex={0}
                        className={classNames(`nav-link d-flex justify-content-between`, active && 'active')}
                    >
                        <span>{children[0]}</span>
                        <i
                            className={classNames(
                                'fa navbar-mobile-menu-arrow',
                                this.isOpen() ? 'fa-chevron-up' : 'fa-chevron-down'
                            )}
                        />
                    </div>
                </li>
                <Collapse isOpen={this.isOpen()}>
                    <ul className="navbar-nav d-xl-none fs-3">{children[1]}</ul>
                </Collapse>
            </React.Fragment>
        );
    }
}

CollapseItem.Toggle = ({ children }) => children;
CollapseItem.Items = ({ children }) => children;

export default CollapseItem;
