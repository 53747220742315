/* eslint-disable no-param-reassign */
/* License https://github.com/sindresorhus/p-min-delay/blob/master/license */
import delay from './delay';

const pMinDelay = (promise, ms, opts) => {
    opts = Object.assign(
        {
            delayRejection: true
        },
        opts
    );

    let promiseErr;

    if (opts.delayRejection) {
        promise = promise.catch(err => {
            promiseErr = err;
        });
    }

    return Promise.all([promise, delay(ms)]).then(val => (promiseErr ? Promise.reject(promiseErr) : val[0]));
};

export default pMinDelay;
