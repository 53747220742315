import Cookie from 'js-cookie';

export const getToast = state => state.ui.toast;
export const getModal = state => state.ui.modal;
export const getActionPanelOpen = state => state.ui.actionPanelOpen;
export const getShowPageLoader = state => state.ui.showPageLoader;
export const getShowOffcanvas = state => state.ui.showOffcanvas;
export const getShowDealerDropdown = state => state.ui.showDealerDropdown;
export const getShowOffcanvasDealerDropdown = state => state.ui.showOffcanvasDealerDropdown;
export const getShowUserDropdown = state => state.ui.showUserDropdown;
export const getOffcanvasCollapse = state => state.ui.offcanvasCollapse;
export const getShowInitialLoader = state => state.ui.showInitialLoader;

export const getSchibstedStickyFooterCookie = () => Cookie.get('hide_schibsted_sticky_footer');
