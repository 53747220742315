import { loadState, saveState } from 'state/localStorage';
import * as actionTypes from './actionTypes';

const { adType = 'basic', blocketBump, bytbilBump } = loadState() || {};

const defaultState = {
    adType,
    blocketBump,
    bytbilBump
};

const boompReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.SET_BOOMP_TYPE: {
            // action.bumpsSettings comes in as a string so convert it to a JSON object first
            const { adType: adTypeValue } = JSON.parse(action.adType);

            if (typeof window !== 'undefined') {
                saveState({ ...state, ...adTypeValue });
            }

            return {
                ...state,
                adType: adTypeValue
            };
        }

        case actionTypes.SET_MARKETPLACE_TOGGLE: {
            // action.bumpsSettings comes in as a string so convert it to a JSON object first
            const bumpSettings = JSON.parse(action.bumpSettings);

            if (typeof window !== 'undefined') {
                saveState({ ...state, ...bumpSettings });
            }

            return {
                ...state,
                ...bumpSettings
            };
        }

        default:
            return state;
    }
};

export default boompReducer;
