import * as actionTypes from './actionTypes';

const defaultState = {
    sortProps: {
        currentCell: 'deleted',
        sortDirection: '-'
    },
    page: {
        size: 100,
        number: 1
    },
    filters: {
        dealers: '-',
        searchString: ''
    },
    dirty: false
};

const vehiclesHistoryReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.SET_FILTER_VALUE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    searchString: action.filter
                },
                dirty: true
            };

        case actionTypes.SET_FILTER_VALUES:
            return {
                ...state,
                filters: {
                    dealers: action.filterValues.dealers,
                    searchString: action.filterValues.filter
                },
                dirty: true
            };

        case actionTypes.SET_SORT_PROPS:
            return {
                ...state,
                sortProps: action.sortProps,
                dirty: true
            };

        case actionTypes.SET_PAGE_NUMBER:
            return {
                ...state,
                page: {
                    ...state.page,
                    number: action.pageNumber
                },
                dirty: true
            };

        default:
            return state;
    }
};

export default vehiclesHistoryReducer;
