import React from 'react';
import { useSelector } from 'react-redux';

interface State {
    dcb: {
        numPendingOrders?: Number;
    };
}

const PendingDCBOrdersCount = () => {
    const numPendingOrders = useSelector((state: State) => state?.dcb?.numPendingOrders);

    if (typeof numPendingOrders === 'number' && numPendingOrders > 0) {
        return (
            <div className="notifications">
                <div className="count">{numPendingOrders}</div>
            </div>
        );
    }

    return null;
};

export default PendingDCBOrdersCount;
