import pulseTracker from './pulseTracker';
import pulseUpdate from './pulseUpdate';

export const pulseClickUIElement = (name: string, custom?: object) => {
    pulseTracker({
        action: 'Click',
        object: {
            type: 'UIElement',
            name,
            custom
        }
    });
};

export const pulseDragUIElement = (name: string) =>
    pulseTracker({
        action: 'Drag',
        object: {
            type: 'UIElement',
            name
        }
    });

export const pulseViewFrontPage = () =>
    pulseUpdate({
        object: {
            type: 'Frontpage'
        }
    });

export const pulseViewPage = () =>
    pulseUpdate({
        object: {
            type: 'Page'
        }
    });
