import React from 'react';

const firstCharUpper = (str: string) => str.charAt(0).toUpperCase();

const getInitials = (firstName: string, lastName: string) => {
    if (!firstName || !lastName) {
        return '';
    }

    return `${firstCharUpper(firstName)}${firstCharUpper(lastName)}`;
};

const InitialsIcon = ({ firstName, lastName }: { firstName: string; lastName: string }) => (
    <div className="initials-icon">
        <span>{getInitials(firstName, lastName)}</span>
    </div>
);

export default InitialsIcon;
