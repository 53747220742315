import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useMatch } from 'react-router-dom';
import LegacyLink from 'components/LegacyLink';
import WarningLink from 'components/WarningLink';
import { RootState } from 'state/types';
import { EQUIPMENTGROUP_SET_DIRTY } from 'features/equipmentlists/state/actionTypes';

interface Props {
    to?: string;
    rel?: string;
    children: React.ReactNode;
    onClick?(e: React.MouseEvent): void;
    target?: string;
    legacy?: boolean;
    className?: string;
    dataTestId?: string;
    title?: string;
}

interface LegacyProps {
    to: string;
    children: React.ReactNode;
    legacy: boolean | object;
}

const NavigationLink = ({
    to = '',
    rel,
    children = <></>,
    onClick = () => {},
    target,
    legacy = false,
    className = '',
    dataTestId = ''
}: Props) => {
    const dispatch = useDispatch();

    const isOnAddOfferPage = !!useMatch('/offer');
    const isOnEditOfferPage = !!useMatch('/offer/:offerId');
    const isOnVehiclesPage = !!useMatch('/vehicles');
    const isOnEquipmentPage = !!useMatch('/offer/equipment');

    const isOnOfferRelatedPage = isOnAddOfferPage || isOnEditOfferPage || isOnVehiclesPage;

    const { dirty: offerDirty } = useSelector((state: RootState) => state.offer?.offerData);
    const { dirty: equipmentGroupDirty } = useSelector((state: RootState) => state.equipmentGroup);

    const getHeaderText = () => {
        switch (true) {
            case isOnEquipmentPage:
                return 'Du lämnar nu utrustningslistor';
            case isOnOfferRelatedPage:
                return 'Du lämnar nu annonsinläggningen';
            default:
                return 'Du lämnar nu sidan';
        }
    };

    const executeDispatch = () => {
        switch (true) {
            case isOnEquipmentPage:
                dispatch({ type: EQUIPMENTGROUP_SET_DIRTY, dirty: false });
                break;
            case isOnOfferRelatedPage:
                dispatch({ type: '@OFFER/SET_OFFER_FORM_DIRTY', dirty: false });
                dispatch({ type: '@OFFER/RESET_VEHICLE_DATA' });
                break;
            default:
                break;
        }
    };

    const showWarningBeforeNavigate = () => {
        switch (true) {
            case isOnEquipmentPage:
                return equipmentGroupDirty;
            case isOnOfferRelatedPage:
                return offerDirty;
            default:
                return false;
        }
    };

    if (showWarningBeforeNavigate()) {
        return (
            <WarningLink
                href={to}
                target={target}
                rel={rel}
                className={className}
                onClick={onClick}
                executeDispatch={executeDispatch}
                headerText={getHeaderText()}
            >
                {children}
            </WarningLink>
        );
    }

    if (legacy) {
        const legacyProps: LegacyProps = { ...{ to, children, legacy } };
        return <LegacyLink {...legacyProps} />;
    }

    if (typeof target !== 'undefined' || to.startsWith('http') || to.startsWith('#')) {
        return (
            <a href={to} target={target} rel={rel} className={className} onClick={onClick} data-testid={dataTestId}>
                {children}
            </a>
        );
    }

    return (
        <NavLink to={to} end onClick={onClick} className={className} data-testid={dataTestId}>
            {children}
        </NavLink>
    );
};

export default NavigationLink;
