import { ApolloClient, ApolloLink } from '@apollo/client';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { createUploadLink } from 'apollo-upload-client';
import apolloLogger from 'apollo-link-logger';
import customFetch from './customFetch';
import createCache from './createCache';

const cache = createCache();

export default function createApolloClient() {
    const apolloLinkOptions = [
        new ApolloLinkTimeout(30000),
        createUploadLink({
            uri: `/graphql`,
            credentials: 'include',
            headers: { 'Apollo-Require-Preflight': 'true' },
            fetch: customFetch
        })
    ];

    if (typeof window !== 'undefined' && window.App && window.App.config && window.App.config.IS_DEV) {
        // Only add apolloLogger to dev
        apolloLinkOptions.unshift(apolloLogger);
    }

    return new ApolloClient({
        link: ApolloLink.from(apolloLinkOptions),
        cache: cache.restore(window.App.apolloData),
        queryDeduplication: true
    });
}
