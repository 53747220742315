import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { throttle } from 'lodash';
import rootReducer from '../state';
import { saveState } from '../state/localStorage';

import createLogger from './logger';
import { api } from '../middleware/api';
import crashReporter from '../middleware/crashReporter';
import checksumMiddleware from '../middleware/checksumMiddleware';

export default function configureStore(initialState, config, additionalMiddleware = []) {
    const middleware = [
        thunk,
        crashReporter(config.logger), // Sentry
        api(config.fetch, config.xhr, config.logger),
        checksumMiddleware,
        ...additionalMiddleware
    ];

    let enhancer;
    const { disableLogging } = config;

    if (
        typeof config.IS_DEV !== 'undefined' &&
        config.IS_DEV &&
        !(typeof disableLogging !== 'undefined' && disableLogging)
    ) {
        middleware.push(createLogger());

        // https://github.com/zalmoxisus/redux-devtools-extension#redux-devtools-extension
        let devToolsExtension = f => f;
        if (process.env.BROWSER && window.devToolsExtension) {
            devToolsExtension = window.devToolsExtension();
        }

        enhancer = compose(applyMiddleware(...middleware), devToolsExtension);
    } else {
        enhancer = applyMiddleware(...middleware);
    }

    const store = createStore(rootReducer, initialState, enhancer);

    store.subscribe(
        // store.subscribe is called everytime the store state changes so to avoid this
        // being called too often we wrap it in throttle as JSON.stringify is expensive
        throttle(() => {
            const storeState = store.getState();

            if (storeState) {
                saveState({
                    featureToggle: {
                        banners: (storeState.featureToggle && storeState.featureToggle.banners) || undefined
                    },
                    vehicles: {
                        showMainThumb: storeState.activeAds.showMainThumb,
                        viewMode: storeState.activeAds.viewMode
                    },
                    boomp: {
                        adType: (storeState.boomp && storeState.boomp.adType) || 'basic',
                        blocketBump: (storeState.boomp && storeState.boomp.blocketBump) || false,
                        bytbilBump: (storeState.boomp && storeState.boomp.bytbilBump) || false
                    }
                });
            }
        }, 1000)
    );

    return store;
}
