import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/types';

const UserMeta = () => {
    const {
        user: { first_name: firstName, last_name: lastName, email }
    } = useSelector((state: RootState) => state.iua) || {};

    return (
        <div className="user-meta">
            <div className="user-meta__info">
                <div className="user-meta__name mb-3 ellipsis">
                    {firstName} {lastName}
                </div>
                <div className="user-meta__email ellipsis">{email}</div>
            </div>
        </div>
    );
};

export default UserMeta;
