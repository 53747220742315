import * as FEATURE_TOGGLE from './actionTypes';

export const defaultState = {
    ft: {
        bump: false,
        offer: false
    }
};

export default function user(state = defaultState, action) {
    switch (action.type) {
        case FEATURE_TOGGLE.UPDATE_SETTINGS:
            return {
                ...state,
                ft: {
                    ...state.ft,
                    ...action.patch
                }
            };

        default:
            return state;
    }
}
