import React from 'react';
import classNames from 'classnames';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from '@bytbil/bootstrap-react';

interface Props {
    /** Function for toggling visibility */
    toggle: Function;
    /** Modal body content. Make sure to make some part of the body scrollable if it is large - we want to see the modal footer on screen */
    body: React.ReactNode;
    /** Is modal open? */
    isOpen?: boolean;
    /** Modal header content */
    header: React.ReactNode;
    /** Text for abort button */
    abortButtonText?: string;
    /** Is something loading? Yes = disable the abort button */
    isLoading?: boolean;
    /** Use this to ovveride the default sizing of the modal, and have a fixed size instead. Default behavior is a small modal that grows with its content */
    size?: 'sm' | 'md' | 'lg';
    /** Additional classes applied on Modal component */
    className?: string;
    /** Should modal fade in/out when toggling visibility? */
    fade?: boolean;
}

const DialogModal = ({
    isOpen = false,
    toggle,
    body,
    header,
    abortButtonText = 'Stäng',
    isLoading = false,
    className = '',
    fade = true,
    size = 'md'
}: Props) => {
    if (!isOpen) return null;

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            size={size}
            fade={fade}
            className={classNames('modal-with-footer-bg autocenter-modal', className)}
        >
            <ModalHeader tag="h4" toggle={toggle}>
                {header}
            </ModalHeader>
            <ModalBody>{body}</ModalBody>
            <ModalFooter className="justify-content-center mt-3">
                <Button color="link" onClick={toggle} disabled={isLoading}>
                    {abortButtonText}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default DialogModal;
