import createApolloClient from 'core/createApolloClient';

import CHANGE_DEALER from 'features/iua/mutations/changeDealer';
import * as USER from '../user/actionTypes';
import * as IUA_USER from './actionTypes';

export const initAuth = data => async dispatch => {
    dispatch({ type: IUA_USER.SET_AUTHENTICATED, data });

    // A bunch of calls we need to do to keep Admin UI happy and working, let's try and
    // get rid of this as soon as possible
    dispatch({ type: USER.SET_AUTHENTICATED });
};

export const logout = () => async dispatch => {
    // can we make a call apollo server from here? because we need to delete the session token
    // and that can only be done from our node server as it is a httpOnly cookie

    dispatch({ type: IUA_USER.SET_UNAUTHENTICATED });
};

export const setUserData = data => async dispatch => {
    dispatch({ type: IUA_USER.SET_USER_DATA, data });
};

export const changeIUAMainDealer = dealerId => async () => {
    const apolloClient = createApolloClient();

    await apolloClient.mutate({
        variables: {
            dealerId
        },
        mutation: CHANGE_DEALER
    });
};

export const updateChecksum = checksum => async dispatch => {
    dispatch({ type: IUA_USER.UPDATE_CHECKSUM, checksum });
};
