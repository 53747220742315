/* eslint-disable no-undef */
import React from 'react';
import { Button } from '@bytbil/bootstrap-react';
import Icon from '@bytbil/fontawesome-6';

const ChatBot = () => {
    const openChat = () => {
        try {
            // @ts-ignore
            kindlyChat.openChat();
        } catch (e) {
            console.error('something went wrong with kindly');
        }
    };

    return (
        <Button onClick={openChat} color="primary" className="support-btn">
            <Icon name="comment" type="regular" />
            <span>Support</span>
        </Button>
    );
};

export default ChatBot;
