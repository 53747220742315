import React from 'react';
import { useLocation } from 'react-router-dom';

import { Icon } from '@bytbil/general-react';

import NavigationItem from 'components/Navigation/NavigationItem';
import SubNavigationItems from 'components/Navigation/SubNavigationItems';
import hasProduct from 'core/hasProduct';
import { useSelector } from 'react-redux';
import { RootState } from 'state/types';
import StockNavigationItems from './StockNavigationItems';
import StatisticsNavigationItems from './StatisticsNavigationItems';
import DealerNavigationItems from './DealerNavigationItems';
import CustomerServiceNavigationItems from './CustomerServiceNavigationItems';
import AccountNavigationItems from './AccountNavigationItems';
import ComposeNavigationItems from './ComposeNavigationItems';
import DCBNavigationItems from './DCBNavigationItems';
import PendingDCBOrdersCount from '../PendingDCBOrdersCount';

interface OffcanvasCollapse {
    id: string;
    show: boolean;
    isSubsectionTo?: string;
}

interface Props {
    className: string;
    offcanvasCollapse?: OffcanvasCollapse;
    toggleOffcanvasCollapse?: (id: string, show: boolean, isSubsectionTo?: string) => void;
}

const NavigationOffcanvas = ({ className, toggleOffcanvasCollapse, offcanvasCollapse }: Props) => {
    const { pathname } = useLocation();
    const { products } = useSelector((state: RootState) => state.dealer.dealerApi);

    return (
        <ComposeNavigationItems origin="header">
            {({ onNavigationClick }) => (
                <ul className={className}>
                    <NavigationItem id="offer" to="/offer" onClick={onNavigationClick}>
                        <Icon name="plus" className="pr-2" />
                        Nytt fordon
                    </NavigationItem>

                    <SubNavigationItems
                        id="vehicles"
                        toggleOffcanvasCollapse={toggleOffcanvasCollapse}
                        offcanvasCollapse={offcanvasCollapse}
                    >
                        <SubNavigationItems.Toggle>
                            <Icon name="car" className="pr-2" />
                            Fordon
                        </SubNavigationItems.Toggle>
                        <SubNavigationItems.Items>
                            <StockNavigationItems />
                        </SubNavigationItems.Items>
                    </SubNavigationItems>

                    <SubNavigationItems
                        id="statistics"
                        toggleOffcanvasCollapse={toggleOffcanvasCollapse}
                        offcanvasCollapse={offcanvasCollapse}
                    >
                        <SubNavigationItems.Toggle>Statistik</SubNavigationItems.Toggle>
                        <SubNavigationItems.Items>
                            <StatisticsNavigationItems />
                        </SubNavigationItems.Items>
                    </SubNavigationItems>

                    <SubNavigationItems
                        id="dealer"
                        toggleOffcanvasCollapse={toggleOffcanvasCollapse}
                        offcanvasCollapse={offcanvasCollapse}
                    >
                        <SubNavigationItems.Toggle>Anläggning</SubNavigationItems.Toggle>
                        <SubNavigationItems.Items>
                            <DealerNavigationItems />
                        </SubNavigationItems.Items>
                    </SubNavigationItems>

                    {hasProduct({ products, productCode: 'dcb' }) && (
                        <SubNavigationItems
                            id="dcb-orders"
                            toggleOffcanvasCollapse={toggleOffcanvasCollapse}
                            offcanvasCollapse={offcanvasCollapse}
                        >
                            <SubNavigationItems.Toggle>
                                Reservationer <PendingDCBOrdersCount />
                            </SubNavigationItems.Toggle>
                            <SubNavigationItems.Items>
                                <DCBNavigationItems />
                            </SubNavigationItems.Items>
                        </SubNavigationItems>
                    )}

                    <NavigationItem id="pricing" to="/pricing" onClick={onNavigationClick}>
                        Priskollen
                    </NavigationItem>

                    <SubNavigationItems
                        id="customerservice"
                        toggleOffcanvasCollapse={toggleOffcanvasCollapse}
                        active={pathname === 'customerservice'}
                        offcanvasCollapse={offcanvasCollapse}
                        isPublic
                    >
                        <SubNavigationItems.Toggle>
                            <Icon name="life-ring" className="pr-2" />
                            Kundservice
                        </SubNavigationItems.Toggle>
                        <SubNavigationItems.Items>
                            <CustomerServiceNavigationItems />
                        </SubNavigationItems.Items>
                    </SubNavigationItems>

                    <SubNavigationItems
                        id="account"
                        active={pathname === 'account'}
                        toggleOffcanvasCollapse={toggleOffcanvasCollapse}
                        offcanvasCollapse={offcanvasCollapse}
                    >
                        <SubNavigationItems.Toggle>
                            <Icon name="user" className="pr-2" />
                            Ditt konto
                        </SubNavigationItems.Toggle>
                        <SubNavigationItems.Items>
                            <AccountNavigationItems />
                        </SubNavigationItems.Items>
                    </SubNavigationItems>
                </ul>
            )}
        </ComposeNavigationItems>
    );
};

export default NavigationOffcanvas;
