import * as RESET from './actionTypes';

export const defaultState = {
    status: {
        isFetching: false,
        hasSuccess: false
    },
    error: {
        status: false,
        type: undefined
    }
};

const reset = (state = defaultState, action) => {
    switch (action.type) {
        case RESET.RESET_USER_PASSWORD_REQUEST:
            return {
                ...state,
                status: {
                    isFetching: true,
                    hasSuccess: false
                },
                error: defaultState.error
            };

        case RESET.RESET_USER_PASSWORD_RESPONSE:
            return {
                ...state,
                status: {
                    isFetching: false,
                    hasSuccess: true
                },
                error: defaultState.error
            };

        case RESET.RESET_USER_PASSWORD_ERROR:
            return {
                ...state,
                status: {
                    isFetching: false,
                    hasSuccess: false
                },
                error: {
                    status: true,
                    type: action.error
                }
            };

        default:
            return state;
    }
};

export default reset;
