import Cookie from 'js-cookie';
import { loadState, saveState } from 'state/localStorage';

import * as actionTypes from './actionTypes';

export const getStorageCostCookie = () => Cookie.get('storage_cost');
export const getColumnsCookie = () => Cookie.getJSON('statistics_columns');
export const getGearboxColumnCookie = () => Cookie.getJSON('unchecked_gearbox_column');
export const getRankSettingLocalCookie = () => Cookie.get('rank_setting_local');
export const getRankSettingTypeCookie = () => Cookie.get('rank_setting_type');
export const getSortStateCookie = () => Cookie.get('sort_state');
export const getFilterSelectionCookie = () => Cookie.get('vehiclesFilterSelection');
export const getVehiclesDatesCookie = () => Cookie.get('vehiclesDates');

const localStorageState = loadState() || {};

const defaultState = {
    columns: getColumnsCookie(),
    gearboxUnchecked: getGearboxColumnCookie(),
    filterSelection: getFilterSelectionCookie(),
    rankSettingLocal: getRankSettingLocalCookie(),
    rankSettingType: getRankSettingTypeCookie(),
    selectedVehicleIds: [],
    selectedVehicles: [],
    showMainThumb: localStorageState.showMainThumb,
    sortState: getSortStateCookie(),
    storageCost: getStorageCostCookie(),
    vehicles: [],
    vehiclesDates: getVehiclesDatesCookie(),
    viewMode: localStorageState.viewMode
};

const vehiclesReducer = (state = defaultState, action) => {
    const COOKIE_DOMAIN = typeof window !== 'undefined' ? (window as any).App.config.cookieDomain : null;

    switch (action.type) {
        case actionTypes.SET_COLUMN_PREFERENCES:
            if (typeof window !== 'undefined') {
                Cookie.set('statistics_columns', action.columns, {
                    expires: 365,
                    domain: COOKIE_DOMAIN
                });
            }
            return {
                ...state,
                columns: action.columns
            };
        case actionTypes.SET_UNCHECKED_GEARBOX_COLUMN:
            if (typeof window !== 'undefined') {
                Cookie.set('unchecked_gearbox_column', action.gearboxUnchecked, {
                    expires: 365,
                    domain: COOKIE_DOMAIN
                });
            }
            return {
                ...state,
                gearboxUnchecked: action.gearboxUnchecked
            };
        case actionTypes.SET_STORAGE_COST:
            if (typeof window !== 'undefined') {
                Cookie.set('storage_cost', action.storageCost, {
                    expires: 365,
                    domain: COOKIE_DOMAIN
                });
            }

            return {
                ...state,
                storageCost: action.storageCost
            };
        case actionTypes.SET_RANK_SETTINGS:
            if (typeof window !== 'undefined') {
                Cookie.set('rank_setting_local', action.rankSettingLocal, {
                    expires: 365,
                    domain: COOKIE_DOMAIN
                });
                Cookie.set('rank_setting_type', action.rankSettingType, {
                    expires: 365,
                    domain: COOKIE_DOMAIN
                });
            }
            return {
                ...state,
                rankSettingLocal: action.rankSettingLocal,
                rankSettingType: action.rankSettingType
            };

        case actionTypes.SET_SORT_STATE:
            if (typeof window !== 'undefined') {
                Cookie.set('sort_state', action.sortState, {
                    expires: 365,
                    domain: COOKIE_DOMAIN
                });
            }

            return {
                ...state,
                sortState: action.sortState
            };

        case actionTypes.SET_FILTER_SELECTION: {
            const filterAsJSON = JSON.parse(action.filter);

            const updatedFilterSelection = { ...state.filterSelection, ...filterAsJSON };

            const cookieValue = JSON.stringify(updatedFilterSelection);

            if (typeof window !== 'undefined') {
                Cookie.set('vehiclesFilterSelection', cookieValue, {
                    expires: 365,
                    domain: COOKIE_DOMAIN
                });
                Cookie.set('vehiclesFilterSelection', cookieValue, {
                    expires: 365,
                    domain: COOKIE_DOMAIN
                });
            }

            return {
                ...state,
                filterSelection: updatedFilterSelection
            };
        }

        case actionTypes.SET_DATE_FILTER: {
            const filterAsJSON = JSON.parse(action.vehiclesDates);

            const updatedDates = { ...state.vehiclesDates, ...filterAsJSON };

            const cookieValue = JSON.stringify(updatedDates);

            if (typeof window !== 'undefined') {
                Cookie.set('vehiclesDatesFilter', cookieValue, {
                    expires: 365,
                    domain: COOKIE_DOMAIN
                });
                Cookie.set('vehiclesDatesFilter', cookieValue, {
                    expires: 365,
                    domain: COOKIE_DOMAIN
                });
            }

            return {
                ...state,
                vehiclesDates: updatedDates
            };
        }

        case actionTypes.RESET_FILTERS:
            return {
                ...state,
                filterSelection: undefined,
                vehiclesDates: undefined
            };

        case actionTypes.SET_SELECTED_VEHICLES:
            return {
                ...state,
                selectedVehicles: action.selectedVehicles,
                selectedVehicleIds: action.selectedVehicleIds
            };

        case actionTypes.SET_VEHICLES:
            return {
                ...state,
                selectedVehicleIds: action.selectedVehicleIds
            };

        case actionTypes.SET_SHOW_MAIN_THUMB: {
            if (typeof window !== 'undefined') {
                saveState({ ...localStorageState, showMainThumb: action.showMainThumb });
            }

            return {
                ...state,
                showMainThumb: action.showMainThumb
            };
        }

        case actionTypes.SET_VIEW_MODE_PREFERENCE: {
            if (typeof window !== 'undefined') {
                saveState({ ...localStorageState, viewMode: action.viewMode });
            }

            return {
                ...state,
                viewMode: action.viewMode
            };
        }

        default:
            return state;
    }
};

export default vehiclesReducer;
