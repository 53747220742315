import React from 'react';
import { useDispatch } from 'react-redux';

import { Alert } from '@bytbil/bootstrap-react';
import { Icon } from '@bytbil/general-react';

const themeMap = {
    INFO: { icon: 'info-circle', color: 'primary' },
    WARNING: { icon: 'exclamation-triangle', color: 'warning' }
};

interface Notification {
    id: string;
    message: string;
    type: string;
    url: string;
}

interface Props {
    classNames: string;
    notifications: Notification[];
}

const AdminAlert = ({ classNames, notifications }: Props) => {
    const dispatch = useDispatch();

    if (!notifications.length) {
        return null;
    }

    const elements = notifications.map((notification, index, array) => {
        const { id, message, type, url } = notification;

        const { icon, color } = themeMap[type || 'INFO'];
        const sameTypeAsNext = array[index + 1] && array[index + 1].type === type;

        return (
            <Alert
                key={id}
                color={`${color}`}
                className={`admin-notification px-0 border-0 rounded-0 m-0 ${
                    sameTypeAsNext ? `with-border-${color}` : ''
                }`}
                // transition={{ timeout: 500, unmountOnExit: true }}
            >
                <div className="container d-flex flex-column flex-sm-row justify-content-between">
                    <div>
                        <Icon name={icon} className="alert-icon" />
                        {message}
                    </div>
                    {url && (
                        <a
                            href={`${url}`}
                            rel="noopener noreferrer"
                            target="_blank"
                            onClick={() => dispatch({ type: '@GENERAL/NOTIFICATION_READ_MORE_LINK' })}
                        >
                            Läs mer
                            <Icon name="external-link" className="link-icon" />
                        </a>
                    )}
                </div>
            </Alert>
        );
    });
    return <div className={classNames}>{elements}</div>;
};

export default AdminAlert;
