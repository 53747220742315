import gql from 'graphql-tag';

export default gql`
    query GetDealerByCode($dealerCode: String!) {
        dealerByDealerCode(dealerCode: $dealerCode) {
            id
            name
            description
            code
            created

            phoneNumber
            website

            orgNumber
            visitingAddress
            postalNumber
            postAddress
            city
            county

            emailDisplay
            emailLead
            emailImport
            emailExport

            deliveryDescription

            products {
                name
                code
                settings
            }

            tags {
                tag
            }

            groups {
                id
                code
                name
                admin
            }

            groupMembers {
                id
                code
            }

            marketplaces {
                marketplace
                url
            }

            authorizedBrands
        }
    }
`;
