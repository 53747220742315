import React from 'react';
import classNames from 'classnames';
import { Navbar, NavbarToggler } from '@bytbil/bootstrap-react';

import DealerDropdown from 'components/DealerDropdown';
import Logotype from 'components/Logotype';

import { RootState } from 'state/types';
import { useSelector } from 'react-redux';
import MobileOffcanvas from './MobileOffcanvas';
import MobileHeaderNavigationItems from './Navigation/MobileHeaderNavigationItems';

interface User {
    login: string;
    level: number;
}

interface OffcanvasCollapse {
    id: string;
    show: boolean;
    isSubsectionTo?: string;
}

interface Props {
    toggleOffcanvas: (e: React.SyntheticEvent) => void;
    dealerDropdown: boolean;
    setDealer: (dealerId: number) => void;
    toggleDealerDropdown: () => void;
    offcanvas: boolean;
    isAuthed: boolean;
    user: User;
    logoClick: () => void;
    toggleOffcanvasCollapse: (id: string, show: boolean, isSubsectionTo: string) => void;
    offcanvasCollapse?: OffcanvasCollapse;
}

const MobileHeader = React.memo((props: Props) => {
    const {
        toggleOffcanvas,
        offcanvas,
        isAuthed,
        user,
        setDealer,
        dealerDropdown,
        toggleDealerDropdown,

        logoClick,
        toggleOffcanvasCollapse,
        offcanvasCollapse
    } = props;

    const { groupMembers } = useSelector((state: RootState) => state.dealer.dealerApi) || {};
    const { authenticated: isIUAAuthenticated, dealers: iuaDealers } =
        useSelector((state: RootState) => state.iua) || {};

    // Previously, this just check isAuthed from auth-api ... but now we also need to check IUA
    const isAuthedByEither = isIUAAuthenticated || isAuthed;

    /**
     * This should resolve to true based on two conditions:
     * 1. The user is a auth-api user and is part of a group with more than one dealer
     * 2. The user is a IUA user and has access to more than one dealer
     * */
    const shouldRenderDealerDropdown =
        // auth-api...
        (offcanvas && user.level > 0 && groupMembers && groupMembers.length > 1) ||
        // ...or iua
        (offcanvas && iuaDealers && iuaDealers.length > 1);

    return (
        // A lot of extra divs here, looks ugly but is needed because otherwise we'll have a bunch of ghost elements or warnings
        <React.Fragment>
            <div className={classNames('fixed-top d-none d-xl-none h-100', offcanvas && 'd-block')}>
                <MobileOffcanvas
                    toggleOffcanvasCollapse={toggleOffcanvasCollapse}
                    offcanvasCollapse={offcanvasCollapse}
                    offcanvas={offcanvas}
                />
            </div>
            <div className="d-xl-none fixed-top">
                <Navbar
                    tag="header"
                    className={classNames('bg-dark-alt header-main mobile-header px-0')}
                    dark
                    suppressHydrationWarning
                >
                    <div className="container d-flex justify-content-between w-100">
                        <div className="d-flex align-items-center flex-row flex-grow">
                            {shouldRenderDealerDropdown && (
                                <DealerDropdown
                                    isOpen={dealerDropdown}
                                    toggle={toggleDealerDropdown}
                                    setDealer={setDealer}
                                    linkClassName="pl-0"
                                    textTruncate={25}
                                    align="right"
                                />
                            )}
                            <Logotype
                                className={classNames(
                                    offcanvas &&
                                        (user.level < 1 || (user.level > 0 && iuaDealers && iuaDealers.length === 1)) &&
                                        'header-logo-above',
                                    shouldRenderDealerDropdown && 'd-none'
                                )}
                                onClick={logoClick}
                            />
                            <div className="flex-grow">
                                {!offcanvas && (
                                    <ul className="d-flex flex-row navbar-nav justify-content-end fs-2 fs-md-4">
                                        <MobileHeaderNavigationItems />
                                    </ul>
                                )}
                            </div>
                        </div>
                        {isAuthedByEither && (
                            <NavbarToggler
                                className={classNames(
                                    'flex-last',
                                    'align-self-center',
                                    'navbar-toggler',
                                    'transition',
                                    'd-xl-none',
                                    'pl-2',
                                    'pl-sm-3',
                                    offcanvas && 'active'
                                )}
                                onClick={toggleOffcanvas}
                            />
                        )}
                    </div>
                </Navbar>
            </div>
        </React.Fragment>
    );
});

export default MobileHeader;
