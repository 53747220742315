import React from 'react';
import { DropdownItem } from '@bytbil/bootstrap-react';
import { pulseClickUIElement } from 'core/pulse';

interface Props {
    setDealer: (dealerId: number) => void;
    dealerId: number;
    className?: string;
    children: React.ReactNode;
    onclick?: () => void;
}

class DealerDropdownItem extends React.Component<Props> {
    constructor(props) {
        super(props);

        this.setDealer = this.setDealer.bind(this);
    }

    setDealer() {
        const { setDealer, dealerId, onclick } = this.props;
        pulseClickUIElement('Topnav - Changed dealer');

        if (onclick) {
            onclick();
        }

        setDealer(dealerId);
    }

    render() {
        const { children, className } = this.props;
        return (
            <DropdownItem onClick={this.setDealer} className={className}>
                {children}
            </DropdownItem>
        );
    }
}

export default DealerDropdownItem;
