import gql from 'graphql-tag';

export default gql`
    query GetPendingOrders($dealerCode: String!) {
        pendingOrders(where: { dealerCode: $dealerCode }) {
            count
            orders {
                id
                created
                title
                mainMediaUrl
                blocketUrl
                bytbilUrl
                regNumber
                modelYear
                miles
                color
                price
            }
        }
    }
`;
