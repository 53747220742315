const createLogger = (sentry, config, optional = {}) => {
    if (typeof config === 'undefined') {
        return {
            requestHandler: () => (req, res, next) => {
                next();
            },
            errorHandler: () => (err, req, res, next) => {
                next(err);
            },
            captureMessage: message => {
                console.info(`Sentry: ${message}`);
            },
            captureException: (e, c) => {
                console.error(`Sentry: ${e.message}`);
                console.info(c);
            }
        };
    }

    if (typeof config !== 'undefined') {
        sentry.init(config, optional);
    }

    return sentry;
};

export default createLogger;
