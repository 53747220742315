import React, { useEffect } from 'react';
import { FormikValues, FormikProps } from 'formik';
import { Alert } from '@bytbil/bootstrap-react';
import { Field, Textarea } from '@bytbil/general-react';

interface Props {
    errorStatus: boolean;
    bodyText: string | boolean;
}

const CustomerServiceForm = ({
    errorStatus,
    handleSubmit,
    handleBlur,
    handleChange,
    errors,
    values,
    touched,
    bodyText,
    setFieldValue
}: Pick<
    FormikProps<FormikValues>,
    'handleSubmit' | 'values' | 'handleChange' | 'handleBlur' | 'errors' | 'touched' | 'setFieldValue'
> &
    Props) => {
    useEffect(() => {
        if (bodyText) {
            setFieldValue('message', bodyText);
        }
    }, [bodyText, setFieldValue]);

    return (
        <form onSubmit={handleSubmit} noValidate className="row">
            <div className="col-12 col-md-6">
                <Field
                    name="username"
                    type="text"
                    placeholder="T.ex bytbilsthlm"
                    label="Företagskod"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={errors.username}
                    value={values.username}
                    touched={touched.username}
                />
            </div>
            <div className="col-12 col-md-6">
                <Field
                    name="name"
                    type="text"
                    placeholder="Ange ert företagsnamn"
                    label="Företagsnamn"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={errors.name}
                    value={values.name}
                    touched={touched.name}
                />
            </div>
            <div className="col-12 col-md-6">
                <Field
                    name="email"
                    type="email"
                    placeholder="anders.andersson@bytbil.com"
                    label="E-postadress"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={errors.email}
                    value={values.email}
                    touched={touched.email}
                />
            </div>
            <div className="col-12 col-md-6">
                <Field
                    name="phone"
                    type="text"
                    placeholder="0123456789"
                    label="Telefonnummer"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    error={errors.phone}
                    touched={touched.phone}
                />
            </div>
            <div className="col-12 mb-2">
                <Textarea
                    id="message"
                    name="message"
                    placeholder="Beskriv ert ärende"
                    label="Ärende"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.message}
                    error={errors.message}
                    touched={touched.message}
                />
            </div>
            {errorStatus && (
                <div className="col-12">
                    <Alert color="danger">Oj, något gick fel. Försök igen senare</Alert>
                </div>
            )}
        </form>
    );
};

export default CustomerServiceForm;
