import wnumb from 'wnumb';

export const priceFormat = {
    thousand: ' ',
    suffix: ' kr'
};

export const thousandFormat = {
    thousand: ' '
};

export const thousandFormatter = (value, suffix) => {
    const formattedValue = wnumb(thousandFormat).to(value);

    if (typeof value !== 'undefined' && typeof suffix !== 'undefined') {
        return `${formattedValue}${suffix}`;
    }

    return formattedValue;
};
