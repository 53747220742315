import React from 'react';
import classNames from 'classnames';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from '@bytbil/bootstrap-react';

interface Props {
    /** Function for toggling visibility */
    toggle: Function;
    /** Modal body content. Make sure to make some part of the body scrollable if it is large - we want to see the modal footer on screen */
    body: React.ReactNode;
    /** Is modal open? */
    isOpen?: boolean;
    /** What should happen when user presses submit? */
    onSubmit: Function;
    /** What should happen when user presses abort? */
    onAbort: Function;
    /** Modal header content */
    header: React.ReactNode;
    /** Text for abort button */
    abortButtonText?: string;
    /** Text for submit button */
    submitButtonText?: string;
    /** Icon for submit button */
    submitButtonIcon?: string;
    /** Color of submit button */
    submitButtonColor?: 'default' | 'primary' | 'success' | 'info' | 'warning' | 'danger' | 'link';
    /** Is something loading? Yes = display a loader in submit button and disable the abort button */
    isLoading?: boolean;
    /** Use this to ovveride the default sizing of the modal, and have a fixed size instead. Default behavior is a small modal that grows with its content */
    size?: 'sm' | 'md' | 'lg';
    /** Additional classes applied on Modal component */
    className?: string;
    /** Should modal fade in/out when toggling visibility? */
    fade?: boolean;
    /** Should we show a submit button? */
    showSubmitButton?: boolean;
    headerClass?: string;
    bodyClass?: string;
    submitDisabled?: boolean;
    submitButtonAriaLabel?: string;
}

const DialogModal = ({
    isOpen = false,
    toggle,
    body,
    onSubmit,
    onAbort,
    header,
    isLoading = false,
    abortButtonText = 'Avbryt',
    submitButtonText = 'Spara',
    submitButtonIcon = '',
    submitButtonColor = 'success',
    className = '',
    fade = true,
    size = 'md',
    showSubmitButton = true,
    headerClass = '',
    bodyClass,
    submitDisabled = false,
    submitButtonAriaLabel = ''
}: Props) => {
    if (!isOpen) return null;

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            fade={fade}
            size={size}
            className={classNames('modal-with-footer-bg autocenter-modal modal-body-overflow', className)}
        >
            <ModalHeader tag="h4" toggle={toggle} className={headerClass}>
                {header}
            </ModalHeader>
            <ModalBody className={bodyClass}>{body}</ModalBody>
            <ModalFooter className="justify-content-end mt-3">
                <Button color="deafult" onClick={onAbort} outline disabled={isLoading}>
                    {abortButtonText}
                </Button>
                {showSubmitButton && (
                    <Button
                        aria-label={submitButtonAriaLabel}
                        color={submitButtonColor}
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={submitDisabled}
                    >
                        {submitButtonIcon && <i className={`fa fa-${submitButtonIcon} mr-2`} />}
                        {submitButtonText}
                    </Button>
                )}
            </ModalFooter>
        </Modal>
    );
};

export default DialogModal;
