import gql from 'graphql-tag';

export default gql`
    mutation SendCustomerSupportEmail($data: CustomerSupportEmailInput!) {
        sendCustomerSupportEmail(data: $data) {
            status
            message
        }
    }
`;
