import React from 'react';
import classNames from 'classnames';

interface Props {
    tag?: keyof JSX.IntrinsicElements;
    autocenter?: boolean;
    className?: string;
    size?: number;
}

const Loader = ({ tag: Tag = 'i', className = '', autocenter = false, size = 1 }: Props) => {
    const composedClassName = classNames('fa', 'fa-circle-o-notch', 'fa-spin', `fa-${size}x`, className);

    if (autocenter) {
        return (
            <div className="d-flex h-100 flex-column justify-content-center text-center">
                <Tag className={composedClassName} />
            </div>
        );
    }

    return <Tag data-testid="loader" className={composedClassName} />;
};

export default Loader;
