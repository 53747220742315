import Cookie from 'js-cookie';
import { actions as iuaActions } from 'state/iua';

const checksumMiddleware = store => next => action => {
    // Call the next middleware in the chain first and store the result
    const result = next(action);

    // Get the current checksum value from the Redux store
    const currentChecksum = store.getState().iua.checksum;

    // Get the checksum value from the cookie
    const cookieChecksum = Cookie.get('blocket_admin_session');

    // If the values are different, dispatch an action to update the Redux store
    if (currentChecksum !== cookieChecksum && cookieChecksum !== undefined) {
        store.dispatch(iuaActions.updateChecksum(cookieChecksum));
    }

    // Return the result of next(action)
    return result;
};

export default checksumMiddleware;
