import React from 'react';
import { useImmer } from 'use-immer';
import { useNavigate } from 'react-router-dom';

import DialogModal from 'components/Modals/DialogModal';

interface Props {
    href: string;
    headerText: string;
    children?: React.ReactNode;
    target?: string;
    className?: string;
    rel?: string;
    onClick?: (e: React.MouseEvent) => void;
    executeDispatch?: () => void;
}

const WarningLink = ({ href, onClick, children, className, target, rel, headerText, executeDispatch }: Props) => {
    const navigate = useNavigate();

    const [state, setState] = useImmer({
        show: false
    });

    function toggleModal() {
        setState(draft => {
            draft.show = !state.show;
        });
    }

    function navigateUser(e) {
        toggleModal();
        if (onClick) {
            onClick(e);
        }

        if (executeDispatch) {
            executeDispatch();
        }

        navigate(href);
    }

    function handleWarningLinkClick(e) {
        e.preventDefault();

        return toggleModal();
    }

    return (
        <React.Fragment>
            <a href={href} target={target} rel={rel} className={className} onClick={handleWarningLinkClick}>
                {children}
            </a>
            <DialogModal
                isOpen={state.show}
                toggle={toggleModal}
                onSubmit={navigateUser}
                onAbort={toggleModal}
                header={headerText}
                body="Dina ändringar kommer inte att sparas."
                submitButtonText="Lämna utan att spara"
                submitButtonColor="warning"
            />
        </React.Fragment>
    );
};

export default WarningLink;
