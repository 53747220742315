import 'scss/style.scss';

import 'core-js/features/array/from';
import 'core-js/features/array/find-index';
import 'core-js/features/array/includes';
import 'core-js/features/array/find';
import 'core-js/features/set';
import 'core-js/features/object/assign';
import 'core-js/features/object/is';
import 'core-js/features/object/keys';
import 'core-js/features/object/values';
import 'core-js/features/object/entries';
import 'core-js/features/promise';
import 'core-js/features/string/includes';
import 'core-js/features/string/ends-with';
import 'core-js/features/string/starts-with';
import 'core-js/features/number/is-nan';
import 'core-js/features/number/parse-int';
import 'core-js/features/number/parse-float';
import 'core-js/features/number/is-finite';
import 'core-js/features/number/is-integer';
import 'core-js/features/symbol';
import 'core-js/features/map';

import 'whatwg-fetch';
import 'intersection-observer';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { loadableReady } from '@loadable/component';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import createApolloClient from 'core/createApolloClient';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import App from 'components/App';
import universalFetch from 'core/universalFetch';
import createXHR from 'core/createXHR';
import configureStore from 'store/configureStore';
import createLogger from 'core/createLogger';
import { loadState } from './state/localStorage';

/**
 * JSON.parse the props that server.js sends to the client in string format
 */
const safeParseAppProps = () => {
    try {
        window.App.config = JSON.parse(window.App.config);
        window.App.apolloData = JSON.parse(window.App.apolloData);
        window.App.state = JSON.parse(window.App.state);
        window.App.context = JSON.parse(window.App.context);
    } catch (e) {
        window.App.config = {};
        window.App.apolloData = {};
        window.App.state = {};
        window.App.context = {};
    }
};

if (typeof window.location.origin === 'undefined') {
    window.location.origin = `${window.location.protocol}//${window.location.host}`;
}

// Actually parse the props
safeParseAppProps();

const apolloClient = createApolloClient();

// Fixes :active state on IOS devices
// https://developers.google.com/web/fundamentals/design-and-ux/input/touch/
window.onload = () => {
    if (/iP(hone|ad)/.test(window.navigator.userAgent)) {
        document.body.addEventListener('touchstart', () => {}, false);
    }
};

const fetch = universalFetch(window.fetch, {
    baseUrl: `${window.location.origin}/api/v1`
});

const xhr = createXHR({
    baseUrl: `${window.location.origin}/api/v1`
});

if (typeof window.App.context !== 'undefined') {
    const CONTEXT_DATA = window.App.context;

    if (CONTEXT_DATA.user) {
        Sentry.setUser({ login: CONTEXT_DATA.user.login, username: CONTEXT_DATA.dealer });
    }
}

let logger;
if (typeof window.App.config.sentry !== 'undefined') {
    const CONFIG_DATA = window.App.config;

    Sentry.setTag('releaseTag', CONFIG_DATA.sentry.optional.extra.version);

    logger = createLogger(
        Sentry,
        { dsn: CONFIG_DATA.sentry.dsn, environment: CONFIG_DATA.sentry.environment },
        CONFIG_DATA.sentry.optional
    );

    // Unhandled promise rejections are not captured by sentry
    window.onunhandledrejection = data => {
        logger.captureException(data.reason);
    };

    window.addEventListener('unhandledrejection', err => {
        const { reason } = err;
        if (err instanceof Error) {
            logger.captureException(reason);
        } else if (Object.prototype.toString.call(reason) === '[object String]') {
            logger.captureMessage(reason);
        }
    });
}

const localStorageState = loadState();

/**
 * Initial configuration for the Redux store, here we set various localStorage or cookie values
 */
const REDUX_STATE = window.App.state;
const CONFIG_DATA = window.App.config;

const store = configureStore(
    {
        ...REDUX_STATE,
        featureToggle: {
            ft: REDUX_STATE.featureToggle.ft,
            banners: localStorageState && localStorageState.featureToggle && localStorageState.featureToggle.banners
        },
        activeAds: {
            ...REDUX_STATE.activeAds,
            showMainThumb:
                (localStorageState && localStorageState.vehicles && localStorageState.vehicles.showMainThumb) || false,
            viewMode:
                (localStorageState && localStorageState.vehicles && localStorageState.vehicles.viewMode) || 'table'
        },
        boomp: {
            ...REDUX_STATE.boomp,
            adType: (localStorageState && localStorageState.boomp && localStorageState.boomp.adType) || 'basic',
            blocketBump: (localStorageState && localStorageState.boomp && localStorageState.boomp.blocketBump) || false,
            bytbilBump: (localStorageState && localStorageState.boomp && localStorageState.boomp.bytbilBump) || false
        }
    },
    {
        logger,
        fetch,
        xhr,
        IS_DEV: CONFIG_DATA.IS_DEV
    }
);

const container = document.getElementById('root');

/**
 * Google Translate fix
 * https://github.com/facebook/react/issues/11538#issuecomment-417504600
 */
if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    // eslint-disable-next-line func-names
    Node.prototype.removeChild = function (child) {
        if (child.parentNode !== this) {
            if (console) {
                console.error('Cannot remove a child from a different parent', child, this);
            }
            return child;
        }
        // eslint-disable-next-line prefer-rest-params
        return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    // eslint-disable-next-line func-names
    Node.prototype.insertBefore = function (newNode, referenceNode) {
        if (referenceNode && referenceNode.parentNode !== this) {
            if (console) {
                console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
            }
            return newNode;
        }
        // eslint-disable-next-line prefer-rest-params
        return originalInsertBefore.apply(this, arguments);
    };
}

loadableReady(() => {
    hydrateRoot(
        container,
        <HelmetProvider>
            <Provider store={store}>
                <ApolloProvider client={apolloClient}>
                    <BrowserRouter>
                        <App
                            context={{
                                ...window.App.context
                            }}
                        />
                    </BrowserRouter>
                </ApolloProvider>
            </Provider>
        </HelmetProvider>
    );
});

if (module.hot) {
    module.hot.accept();
}
