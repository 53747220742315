import * as RECOVER from './actionTypes';

export const defaultState = {
    recoverType: '',
    status: {
        isFetching: false,
        hasSuccess: false
    },
    error: {
        status: false,
        type: undefined
    }
};

const recover = (state = defaultState, action) => {
    switch (action.type) {
        case RECOVER.RECOVER_USER_PASSWORD_REQUEST:
            return {
                ...state,
                status: {
                    isFetching: true,
                    hasSuccess: false
                },
                error: defaultState.error
            };

        case RECOVER.RECOVER_USER_PASSWORD_RESPONSE:
            return {
                ...state,
                status: {
                    isFetching: false,
                    hasSuccess: true
                }
            };

        case RECOVER.RECOVER_USER_PASSWORD_ERROR:
            return {
                ...state,
                status: {
                    isFetching: false,
                    hasSuccess: false
                },
                error: {
                    status: true,
                    type: action.error
                }
            };

        case RECOVER.RECOVER_USER_PASSWORD_SET_RECOVER_TYPE:
            return {
                ...state,
                recoverType: action.recoverType
            };

        case RECOVER.RECOVER_USER_PASSWORD_RESET:
            return defaultState;

        default:
            return state;
    }
};

export default recover;
