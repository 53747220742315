import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useImmer } from 'use-immer';

import { Button } from '@bytbil/bootstrap-react';

import { RootState } from 'state/types';

const SessionChangedReloadBanner = () => {
    // This checksum value is updated by the checksumMiddleware
    const checksum = useSelector((store: RootState) => store.iua.checksum);

    const [state, setState] = useImmer({
        sessionChanged: false,
        reloading: false
    });

    // This ref is used to skip the effect on the initial render
    const initialRender = useRef(true);
    // This ref is used to store the previous checksum value, the reason we want it
    // is we don't want sessionChanged to trigger if the previous checksum was undefined
    const previousChecksum = useRef(checksum);

    useEffect(() => {
        if (initialRender.current) {
            // Skip the effect on the initial render
            initialRender.current = false;
        } else {
            // Don't trigger the banner if the previous checksum is undefined as that
            // means the users cookie won't have been set yet and give a false message to the user
            if (
                previousChecksum.current !== undefined &&
                previousChecksum.current !== 'undefined' &&
                previousChecksum.current !== checksum
            ) {
                setState(draft => {
                    draft.sessionChanged = true;
                });
            }

            // Update the previous checksum ref
            previousChecksum.current = checksum;
        }
    }, [checksum, setState]);

    const reload = () => {
        setState(draft => {
            draft.reloading = true;
        });

        window.location.reload();
    };

    return state.sessionChanged ? (
        <div className="session-changed-banner bg-dark-alt d-none d-xl-block">
            <div className="container">
                <div className="row">
                    <div className="d-flex justify-content-between align-items-center w-100 p-1">
                        <div>
                            <span>
                                <i className="fa fa-exclamation-triangle mr-1 text-warning" />
                                Din session har uppdaterats, vänligen ladda om sidan
                            </span>
                            <p className="mb-0 secondary-text">
                                Detta kan bero på att din profil har uppdaterats eller att du har fått en uppdaterad
                                roll i din organisation
                            </p>
                        </div>
                        <div>
                            <Button disabled={state.reloading} onClick={reload}>
                                Ladda om nu
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default SessionChangedReloadBanner;
