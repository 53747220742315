import * as STATISTICS from '../actionTypes';

export const defaultState = {
    summary: undefined,
    topAds: [],
    bottomAds: [],
    period: undefined,
    status: {
        isFetching: false,
        hasData: false
    },
    error: {
        status: false,
        type: undefined
    }
};

export default function data(state = defaultState, action) {
    switch (action.type) {
        case STATISTICS.FETCH_STATS_REQUEST:
            return {
                ...defaultState,
                status: {
                    isFetching: true,
                    hasData: false
                }
            };

        case STATISTICS.FETCH_STATS_ERROR:
            return {
                ...state,
                status: {
                    isFetching: false,
                    hasData: false
                },
                error: {
                    status: true,
                    type: action.error
                },
                summary: defaultState.summary,
                topAds: defaultState.topAds,
                bottomAds: defaultState.bottomAds
            };

        case STATISTICS.FETCH_STATS_RESPONSE:
            return {
                ...state,
                status: {
                    isFetching: false,
                    hasData: true
                },
                period: action.response.period,
                summary: action.response.summary,
                topAds: action.response.topAds,
                bottomAds: action.response.bottomAds
            };

        default:
            return state;
    }
}
