import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container } from '@bytbil/bootstrap-react';

function FallbackComponent() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Sidan kunde inte visas</title>
            </Helmet>
            <Container className="pt-4 pt-md-5">
                <h1>Sidan kunde inte visas</h1>
                <p>Prova igen om en liten stund.</p>
            </Container>
        </React.Fragment>
    );
}

export default FallbackComponent;
