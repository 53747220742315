export const FETCH_STATS_REQUEST = '@STATISTICS/FETCH_STATS_REQUEST';
export const FETCH_STATS_RESPONSE = '@STATISTICS/FETCH_STATS_RESPONSE';
export const FETCH_STATS_ERROR = '@STATISTICS/FETCH_STATS_ERROR';

export const FETCH_VEHICLE_DATA_REQUEST = '@STATISTICS/FETCH_VEHICLE_DATA_REQUEST';
export const FETCH_VEHICLE_DATA_RESPONSE = '@STATISTICS/FETCH_VEHICLE_DATA_RESPONSE';
export const FETCH_VEHICLE_DATA_ERROR = '@STATISTICS/FETCH_VEHICLE_DATA_ERROR';

// UI
export const FILTER_STATS = '@STATISTICS/FILTER_STATS';
export const SET_DEALER_ID = '@STATISTICS/SET_DEALER_ID';
export const SET_PERIOD = '@STATISTICS/SET_PERIOD';
export const USER_CHANGE_PERIOD = '@STATISTICS/USER_CHANGE_PERIOD';
export const USER_CHANGE_DEALER = '@STATISTICS/USER_CHANGE_DEALER';
export const STATISTICS_AD_CLICK = '@STATISTICS/STATISTICS_AD_CLICK';
