import { createSelector } from 'reselect';

export const getUser = state => state.user.details;
export const getDealerId = state => state.user.dealer.id;
export const getDealerCode = state => state.user.details.login;
export const getIsAuthenticated = state => state.user.auth.authenticated;
export const getLoginError = state => state.user.auth.error;
export const getLoginStatus = state => state.user.auth.status;

const groupMembers = state => state.dealer.dealerApi.groupMembers;

export const getDealer = createSelector([groupMembers, getDealerId], (dealers, dealerId) => {
    let selectedDealer;
    dealers.forEach(dealer => {
        if (dealer.id === dealerId) {
            selectedDealer = dealer;
        }
    });
    return selectedDealer;
});
