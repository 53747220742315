import React from 'react';

interface Props {
    children: string;
    limit: number;
}

const TextTruncate = ({ children, limit }: Props) => {
    let text;

    if (children) {
        text = children.slice(0, limit);
    } else {
        console.warn('TextTruncate: No children provided');
    }

    if (children && children.length > limit) {
        return (
            <span>
                {text}
                ...
            </span>
        );
    }

    return <span>{text}</span>;
};

export default TextTruncate;
