import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useImmer } from 'use-immer';

import { Toast } from '@bytbil/general-react';
import { RootState } from 'state/types';

const ToastContainer = () => {
    const { toast } = useSelector((state: RootState) => state.ui);

    const [state, setState] = useImmer({
        showToast: false
    });

    useEffect(() => {
        setState(draft => {
            draft.showToast = true;
        });

        setTimeout(() => {
            setState(draft => {
                draft.showToast = false;
            });
        }, toast.timeout);
    }, [toast.timestamp, setState, toast.timeout]);

    return <Toast showToast={state.showToast} toast={toast} />;
};

export default ToastContainer;
