import * as AUTH from './actionTypes';

const defaultState = {
    promise: undefined,
    count: 0
};

const refresh = (state = defaultState, action) => {
    switch (action.type) {
        case AUTH.SET_REFRESH_TOKEN_PROMISE:
            return { ...state, promise: action.promise };
        case AUTH.CLEAR_REFRESH_TOKEN_PROMISE:
            return { ...state, promise: defaultState.promise };
        default:
            return state;
    }
};

export default refresh;
