import React from 'react';

interface Props {
    blocketUrl?: string;
    bytbilUrl?: string;
}

const MarketplaceLinks = ({ bytbilUrl, blocketUrl }: Props) => (
    <span>
        {blocketUrl && (
            <a
                href={blocketUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="fw-normal fs-1 text-decoration-underline text-muted mr-3"
            >
                Blocket
            </a>
        )}
        {bytbilUrl && (
            <a
                href={bytbilUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="fw-normal fs-1 text-decoration-underline text-muted"
            >
                Bytbil
            </a>
        )}
    </span>
);

export default MarketplaceLinks;
