import * as BUMP_RECOMMENDATIONS from '../actionTypes';

export const defaultState = {
    status: {
        isFetching: false,
        hasData: false
    },
    error: {
        status: false,
        type: undefined
    },
    data: undefined
};

/**
 * Filter reducer
 */
export default (state = defaultState, action) => {
    switch (action.type) {
        case BUMP_RECOMMENDATIONS.BUMP_RECOMMENDATIONS_REQUEST:
            return {
                ...state,
                status: {
                    isFetching: true,
                    hasData: false
                },
                error: defaultState.error
            };

        case BUMP_RECOMMENDATIONS.BUMP_RECOMMENDATIONS_ERROR:
            return {
                ...state,
                status: {
                    isFetching: false,
                    hasData: false
                },
                error: {
                    status: true,
                    type: undefined
                }
            };

        case BUMP_RECOMMENDATIONS.BUMP_RECOMMENDATIONS_RESPONSE:
            return {
                ...state,
                error: defaultState.error,
                status: {
                    isFetching: false,
                    hasData: true
                },
                data: action.response
            };

        default:
            return state;
    }
};
