// https://pulse.schibsted.io/tracking/start/schema-browser
interface Props {
    object: {
        type: string;
    };
}

const pulseUpdate = ({ object }: Props) => {
    if (typeof pulse === 'undefined') {
        return;
    }

    pulse(tracker => {
        tracker.track('update', {
            '@type': 'View',
            object: {
                '@type': object.type
            }
        });
    });
};

export default pulseUpdate;
