/* License https://github.com/sindresorhus/delay/blob/master/license */

const createAbortError = () => {
    const error = new Error('Delay aborted');
    error.name = 'AbortError';
    return error;
};

const createDelay =
    ({ clearTimeout: clear = clearTimeout, setTimeout: set = setTimeout, willResolve }) =>
    (ms, { value, signal } = {}) => {
        if (signal && signal.aborted) {
            return Promise.reject(createAbortError());
        }

        let timeoutId;
        let settle;
        let rejectFn;

        const signalListener = () => {
            clear(timeoutId);
            rejectFn(createAbortError());
        };

        const cleanup = () => {
            if (signal) {
                signal.removeEventListener('abort', signalListener);
            }
        };

        const delayPromise = new Promise((resolve, reject) => {
            settle = () => {
                cleanup();
                if (willResolve) {
                    resolve(value);
                } else {
                    reject(value);
                }
            };
            rejectFn = reject;
            timeoutId = set(settle, ms);
        });

        if (signal) {
            signal.addEventListener('abort', signalListener, { once: true });
        }

        delayPromise.clear = () => {
            clear(timeoutId);
            timeoutId = null;
            cleanup();
            settle();
        };

        return delayPromise;
    };

const delay = createDelay({ willResolve: true });
delay.reject = createDelay({ willResolve: false });
delay.createWithTimers = ({ clearTimeout, setTimeout }) => {
    const delayWithTimer = createDelay({ clearTimeout, setTimeout, willResolve: true });
    delayWithTimer.reject = createDelay({ clearTimeout, setTimeout, willResolve: false });
    return delayWithTimer;
};

export default delay;
