// Based on https://gist.github.com/ryanflorence/39a37a85254159fd7a5ca54027e175dc
import React from 'react';

let scrollPositions = {};

interface Location {
    key: string;
}

interface Props {
    location: Location;
}

export default class ManageScroll extends React.Component<Props> {
    componentDidMount() {
        try {
            // session storage will throw for a few reasons
            // - user settings
            // - in-cognito/private browsing
            // - who knows...
            const storage = JSON.parse(sessionStorage.getItem('scrollPositions') || '');
            if (storage) {
                scrollPositions = JSON.parse(storage) || {};
                const { key } = this.props.location;
                if (scrollPositions[key]) {
                    window.scrollTo(0, scrollPositions[key]);
                }
            }
        } catch (e) {} // eslint-disable-line

        window.addEventListener('scroll', this.listener);
    }

    componentDidUpdate() {
        const { key } = this.props.location;

        if (!scrollPositions[key]) {
            // never seen this location before
            // (0,0) does not work here with Safari strangely, negative numbers are translated to 0 so it has the same functionality
            window.scrollTo(-1, 0);
        } else {
            // seen it
            window.scrollTo(0, scrollPositions[key]);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listener);
    }

    listener = () => {
        scrollPositions[this.props.location.key] = window.scrollY;
        try {
            sessionStorage.setItem('scrollPositions', JSON.stringify(scrollPositions));
        } catch (e) {} // eslint-disable-line
    };

    render() {
        return null;
    }
}
