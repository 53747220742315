import { actions as connectionActions, selectors as connectionSelectors } from 'state/connections';
import { actions as userActions } from 'state/user';
import { APIClient } from '@bytbil/phoenix-api-client';
// import { APIClient } from '../../../packages/phoenix-api-client/index';

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'Call API';
export const defaultErrorMessage = 'API request failed';
export const authErrorMessage = 'Unauthorized';

export const validateConfig = (endpoint, method) => {
    if (typeof endpoint === 'undefined') {
        throw new Error('Endpoint is not defined');
    }

    if (typeof method === 'undefined') {
        throw new Error('Method is not defined');
    }
};

export const api = (fetch, xhr, logger) => {
    const apiClient = new APIClient({ fetch, xhr });

    return store => next => action => {
        const callAPI = action[CALL_API];

        if (typeof callAPI === 'undefined') {
            return next(action);
        }

        if (callAPI.cancel) {
            const xhrPool = connectionSelectors.getConnectionPool(store.getState());
            if (xhrPool.length > 0) {
                xhrPool.forEach(xhrInstance => xhrInstance.abort());
            }
            return next(connectionActions.clearXHRInstances);
        }

        // mapping to one of the api handlers declared in /core/api
        const callApi = (endpoint, payload, method, onProgress) => {
            const handler = apiClient.endpoints[method][endpoint];
            return handler(payload, onProgress);
        };

        // Decorate the dispatched action with contextual data
        const actionWith = data => {
            const finalAction = Object.assign({}, action, data);
            delete finalAction[CALL_API];
            return finalAction;
        };

        /**
         * endpoint: the api handler to use
         * payload: the payload sent to the endpoint via api handler
         * types: [requestType, successType, failureType]
         * method: http method of the endpoint
         * onProgress: on-progress callback for when we are using XHR
         */
        const { endpoint, payload, types, method, onProgress, publicResource } = callAPI;
        const [requestType, successType, failureType] = types;

        // Check that the API request have defined an endpoint and method
        validateConfig(endpoint, method);

        next(actionWith({ type: requestType, payload }));

        const successAction = response =>
            actionWith({
                response,
                payload,
                type: successType
            });

        const failureAction = error => {
            if (error.message === '401' && !publicResource) {
                store.dispatch(userActions.unauthenticated());
            }

            // Remove this when we have a better way of logging errors in promises
            if (
                (error &&
                    error.message &&
                    error.message !== '500' &&
                    error.message !== '400' &&
                    error.message !== '401' &&
                    error.message !== '403' &&
                    error.message !== '404' &&
                    error.message !== '405' &&
                    error.message !== '499' &&
                    error.message !== '503') ||
                (error && !error.message)
            ) {
                if (logger) {
                    logger.captureException(error, {
                        extras: {
                            action,
                            state: store.getState()
                        }
                    });
                }
            }

            return next(
                actionWith({
                    payload,
                    type: failureType,
                    error: error.message || defaultErrorMessage
                })
            );
        };

        const request = () => {
            let APIPromise = callApi(endpoint, payload, method, onProgress);
            if (APIPromise.xhr && APIPromise.promiseWrapper) {
                const { promiseWrapper, xhr: xhrInstance } = APIPromise;
                APIPromise = promiseWrapper;

                store.dispatch(connectionActions.addXHRInstance(xhrInstance));
            }

            return APIPromise;
        };

        return request()
            .then(response => next(successAction(response)))
            .catch(error => failureAction(error));
    };
};

export default { api };
