export default logger => {
    const captureException = (err, context) => { // eslint-disable-line
        // upload error message and redux context
        logger.captureException(err, {
            extra: context
        });
    };

    return store => next => action => {
        try {
            return next(action); // dispatch
        } catch (err) {
            console.error('Caught an exception!', err);
            // send to crash reporting tool
            captureException(err, {
                action,
                state: store.getState() // dump application state
            });
            throw err; // re-throw error
        }
    };
};
