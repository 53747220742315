import React, { useCallback, useEffect } from 'react';
import { useImmer } from 'use-immer';

import SchibstedPermanentFooter from 'components/SchibstedBanners/SchibstedPermanentFooter';
import SchibstedStickyFooter from 'components/SchibstedBanners/SchibstedStickyFooter';

interface Props {
    hideFooterCookie: boolean;
    dismissFooter(): void;
}

const Footer = ({ hideFooterCookie, dismissFooter }: Props): JSX.Element => {
    const [state, setState] = useImmer({
        footerDismissed: false,
        timesScrolled: 0
    });

    const handleFooterDismiss = useCallback(() => {
        if (!(state.footerDismissed || !!hideFooterCookie)) {
            // Set cookie and state
            dismissFooter();

            setState(draft => {
                draft.footerDismissed = true;
            });
        }
    }, [dismissFooter, hideFooterCookie, state.footerDismissed, setState]);

    /** Scroll event fires a couple of times after render if page is scrolled before refresh (more times if page is scrolled much),
     * we want to ignore that, or footer will never be shown.
     *
     * Number 5 is a bit exaggerated just to be safe * */
    const handleScroll = useCallback(() => {
        if (state.timesScrolled > 5) {
            handleFooterDismiss();
        }
        setState(draft => {
            draft.timesScrolled = state.timesScrolled + 1;
        });
    }, [handleFooterDismiss, state.timesScrolled, setState]);

    // Schibsted sticky footer is dismissed on scroll and when user navigates to another page.
    useEffect(() => {
        if (!(state.footerDismissed || hideFooterCookie)) {
            // Listen for scroll
            window.addEventListener('scroll', handleScroll, true);

            return () => {
                window.removeEventListener('scroll', handleScroll, true);
            };
        }
        return undefined;
    }, [state.footerDismissed, hideFooterCookie, state.timesScrolled, handleScroll]);

    return (
        <React.Fragment>
            {!(state.footerDismissed || hideFooterCookie) ? (
                // Need to have an enclosing div here or the page will show an empty ghost footer if hideFooterCookie = true
                <div>
                    <SchibstedStickyFooter fixedBottom handleDismiss={handleFooterDismiss} />
                </div>
            ) : (
                // Need to return empty div with suppressHydrationWarning here, or it will break stuff
                <div className="d-none" suppressHydrationWarning />
            )}
            <SchibstedPermanentFooter />
        </React.Fragment>
    );
};

export default Footer;
