import * as DCB from './actionTypes';

const defaultState = {
    numPendingOrders: null,
    orders: []
};

const dcbReducer = (state = defaultState, action) => {
    switch (action.type) {
        case DCB.SET_ORDERS:
            return {
                ...state,
                numPendingOrders: action.numPendingOrders,
                orders: action.orders
            };
        case DCB.SET_ORDERS_COUNT:
            return {
                ...state,
                numPendingOrders: action.numPendingOrders
            };
        default:
            return state;
    }
};

export default dcbReducer;
