export const SELECT_VEHICLE_TYPE = '@OFFER/SELECT_VEHICLE_TYPE';
export const SET_VEHICLE_TYPE = '@OFFER/SET_VEHICLE_TYPE';
export const RESET_VEHICLE_TYPE = '@OFFER/RESET_VEHICLE_TYPE';

export const VEHICLE_DATA_POPOVER_ACCEPT = '@OFFER/VEHICLE_DATA_POPOVER_ACCEPT';
export const VEHICLE_DATA_POPOVER_REJECT = '@OFFER/VEHICLE_DATA_POPOVER_REJECT';
export const VEHICLE_DATA_POPOVER_DISMISS = '@OFFER/VEHICLE_DATA_POPOVER_DISMISS';

export const EQUIPMENT_SUGGESTION_POPOVER_SHOW = '@OFFER/EQUIPMENT_SUGGESTION_POPOVER_SHOW';
export const EQUIPMENT_SUGGESTION_POPOVER_ACCEPT = '@OFFER/EQUIPMENT_SUGGESTION_POPOVER_ACCEPT';
export const EQUIPMENT_SUGGESTION_POPOVER_REJECT = '@OFFER/EQUIPMENT_SUGGESTION_POPOVER_REJECT';

export const FORM_SUBMIT = '@OFFER/FORM_SUBMIT';
export const COPY_OFFER_CLICK = '@OFFER/COPY_OFFER_CLICK';

export const SET_OFFER_FORM_DIRTY = '@OFFER/SET_OFFER_FORM_DIRTY';
export const SET_OFFER_FORM_DRAFT_VALUES = '@OFFER/SET_OFFER_FORM_DRAFT_VALUES';
export const SET_OFFER_FORM_SHOW_DRAFT_VALUES = '@OFFER/SET_OFFER_FORM_SHOW_DRAFT_VALUES';

export const SET_REG_NO_TAKEN = '@OFFER/SET_REG_NO_TAKEN';

export const RESET_VEHICLE_DATA = '@OFFER/RESET_VEHICLE_DATA';

export const SET_MODE = '@OFFER/SET_MODE';
