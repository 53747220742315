import { useQuery } from '@apollo/client';
import dealernotifications from './queries/notifications';

// for react to not render duplicate of the same id when user logs out.
const prefixId = (data, prefix) => data.map(n => ({ ...n, id: `${prefix}_${n.id}` }));

export const useNotifications = dealerId => {
    const { scope, body } = dealerId
        ? { scope: 'dealer', body: { variables: { dealerId: parseInt(dealerId, 10) } } }
        : { scope: 'general', body: { skip: true } }; // Skip this if we don't have a signed in dealer

    try {
        const response = useQuery(dealernotifications, body);
        const data = (response.data && response.data[`${scope}Notifications`]) || [];

        return prefixId(data, scope);
    } catch (e) {
        return [];
    }
};

export default useNotifications;
