import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';

import { RootState } from 'state/types';
import { actions as userActions } from 'state/user';

import { pulseClickUIElement } from 'core/pulse';
import useHasActionPermission from 'core/hooks/useHasActionPermission';

import NavigationItem from 'components/Navigation/NavigationItem';

import ComposeNavigationItems from './ComposeNavigationItems';

import LOGOUT from '../../../../graphql/logout';

interface Props {
    className?: string;
}

const AccountNavigationItems = ({ className }: Props) => {
    const dispatch = useDispatch();

    const { authenticated: isIUAAuthenticated } = useSelector((state: RootState) => state.iua) || {};

    const showManageUsersLink = useHasActionPermission('update', 'dealer');

    const [logoutUser] = useMutation(LOGOUT);

    const logout = async () => {
        await logoutUser();

        dispatch(userActions.deauthenticate());
    };

    return (
        <ComposeNavigationItems origin="header">
            {({ onNavigationClick, onCustomerServiceClick }) => (
                <React.Fragment>
                    <NavigationItem
                        id="your-ads"
                        to="/dealer/pricing"
                        className={className}
                        onClick={onNavigationClick}
                    >
                        Dina annonspriser
                    </NavigationItem>
                    <NavigationItem id="news" to="/news" className={className} onClick={onNavigationClick}>
                        Nyheter
                    </NavigationItem>
                    <NavigationItem
                        id="publish-ads"
                        to="https://www.blocket.se/for-foretag/fordonshandlare/publicering_av_annons"
                        className="ml-4"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={onNavigationClick}
                    >
                        Publicera annonser
                    </NavigationItem>
                    <NavigationItem
                        id="questions-and-answers"
                        to="https://blocket.zendesk.com/hc/sv/sections/360000547899-Fordonshandlare"
                        className={className}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={onNavigationClick}
                    >
                        Frågor & svar
                    </NavigationItem>
                    <NavigationItem
                        id="terms-and-conditions"
                        to="https://www.blocket.se/om/villkor/anvandarvillkor-fordon-bytbil"
                        className={className}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={onNavigationClick}
                    >
                        Allmänna villkor
                    </NavigationItem>
                    <NavigationItem id="contact-us" to="#" className={className} onClick={onCustomerServiceClick}>
                        Kontakta oss
                    </NavigationItem>
                    <NavigationItem
                        id="change-password"
                        to={isIUAAuthenticated ? '/user/settings' : '/dealer/changepassword'}
                        className={className}
                        onClick={onNavigationClick}
                    >
                        Inställningar
                    </NavigationItem>
                    {showManageUsersLink && (
                        <NavigationItem
                            id="manage-users"
                            to="/user/manage-users"
                            className={className}
                            onClick={onNavigationClick}
                        >
                            Hantera användare
                        </NavigationItem>
                    )}
                    <NavigationItem
                        onClick={async () => {
                            pulseClickUIElement('Log out');

                            await logout();
                        }}
                        to="#logout"
                        id="logout"
                        className={className}
                    >
                        Logga ut
                    </NavigationItem>
                </React.Fragment>
            )}
        </ComposeNavigationItems>
    );
};
export default AccountNavigationItems;
